import { IServiceOption } from '../config/interfaces';

const cheapOption = (arr: IServiceOption[]): IServiceOption => {
  const sortedArr = [...arr].sort((a, b) => a.total_price
      - b.total_price);

  return sortedArr[0];
};

export default cheapOption;
