import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_API_URL;

interface RootState {
  auth: {
    token: string;
  };
}

export const logOutApi = createApi({
  reducerPath: 'logOutApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;
      headers.set('Content-Type', 'application/json');
      headers.set('Authorization', `Token ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getLogOut: builder.mutation<any, any>({
      query: (arg) => ({
        url: '/api/auth/logout/',
        method: 'DELETE',
        body: arg,
      }),
    }),
  }),
});

export const {
  useGetLogOutMutation,
} = logOutApi;
