import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button } from '@mui/material';

import { serviceCentersApi, useGetLocationServiceCentersQuery } from '../../app/api/serviceCentersApi';

import { KRASNODAR_CENTER, TOKEN_ERROR } from '../../shared/config/constants';
import { useAppDispatch, useAppSelector } from '../../shared/config/hooks';
import { clearServiceCenter, setServiceCenter, setVisibleErrorModal } from '../../shared/store/slices/globalSlice';

import arrowLeft from '../../assets/icons/icon_arrow_left.svg';

import { ErrorModal } from '../../shared/ui';
import { clearTokenData } from '../../shared/store/slices/authSlice';

import { MobileLayout, ServiceCenterList, ServiceCenterMap } from './ui';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const SelectServiceCenterPage: React.FC = () => {
  const [ymapCoordinates, setYmapCoordinates] = useState<number[]>(KRASNODAR_CENTER);
  const [markers, setMarkers] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState<string | null>(null);
  const [showAllCenters, setShowAllCenters] = useState<boolean>(true);
  const [userCoordinates, setUserCoordinates] = useState<number[] | null>(null);
  const [isVisibleList, setIsVisibleList] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');

  const ymapRef = useRef<any>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const autoId = useAppSelector((state) => state.global.autoId);

  const handleError = (error: unknown) => {
    if (error && typeof error === 'object' && 'data' in error) {
      const errorData = (error as { data: { errors?: { detail?: string }[] } }).data;
      if (Array.isArray(errorData.errors) && errorData.errors[0]?.detail) {
        setErrorText(errorData.errors[0].detail);
        dispatch(setVisibleErrorModal(true));
      }
    }
  };

  const { data: centersData, error } = useGetLocationServiceCentersQuery({});

  const isMobile: boolean = window.innerWidth <= 768;

  const handleMapInstance = (map: any) => {
    if (map) ymapRef.current = map;
  };

  const handleSelectServiceCenter = (featureId: string) => {
    setSelectedCenter(featureId);
    setShowAllCenters(false);

    const selectedCenterData = centersData.find((center: any) => center.id.toString() === featureId);
    if (selectedCenterData && ymapRef.current) {
      const coordinates = [selectedCenterData.coordinates.latitude, selectedCenterData.coordinates.longitude];
      ymapRef.current.setCenter(coordinates, ymapRef.current.getZoom(), {
        duration: 300,
      });
      dispatch(setServiceCenter(selectedCenterData));
    }
  };

  const handleShowAllCenters = () => {
    setSelectedCenter(null);
    dispatch(clearServiceCenter());
    setShowAllCenters(true);
  };

  const handleAddServiceCenter = () => {
    navigate('/date-and-time-visit');
  };

  useEffect(() => {
    if (centersData) {
      const newMarkers = centersData.map((center: any) => ({
        id: center.id.toString(),
        coordinates: [center.coordinates.latitude, center.coordinates.longitude],
      }));
      setMarkers(newMarkers);
    }
    if (error) {
      handleError(error);
    }
  }, [centersData, error]);

  useEffect(() => {
    if (navigator.geolocation && !userCoordinates) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          setYmapCoordinates([coords.latitude, coords.longitude]);
          setUserCoordinates([coords.latitude, coords.longitude]);
        },
        () => {
          setYmapCoordinates(KRASNODAR_CENTER);
          setUserCoordinates(null);
        },
        { enableHighAccuracy: true },
      );
    }
  }, []);

  return (
    <div className={cx('container', 'full-height')}>
      <div className={cx('map')}>
        <div className={cx('map__links')}>
          <Link to={`/services/${autoId}`} className={cx('link-goto')}>
            <img src={arrowLeft} alt="Назад" />
            Назад
          </Link>
          {isMobile && (
            <button
              className={cx('button-list')}
              onClick={() => { setIsVisibleList(true); }}
            >
                Список СЦ
            </button>
          )}
        </div>
        <div className={cx('heading')}>
          <h2>Выберите сервисный центр</h2>
        </div>
        <div className={cx('map__container')}>
          {isMobile && isVisibleList && (
            <MobileLayout
              onClose={() => { setIsVisibleList(false); }}
              mainBtnTitle="Выбрать сервис"
              mainBtnAction={() => handleAddServiceCenter()}
            >
              <ServiceCenterList
                selectedCenter={selectedCenter}
                handleShowAllCenters={handleShowAllCenters}
                showAllCenters={showAllCenters}
                centersData={centersData}
                userCoordinates={userCoordinates}
                handleSelectServiceCenter={handleSelectServiceCenter}
              />
            </MobileLayout>
          )}
          {!isMobile && (
            <ServiceCenterList
              selectedCenter={selectedCenter}
              handleShowAllCenters={handleShowAllCenters}
              showAllCenters={showAllCenters}
              centersData={centersData}
              userCoordinates={userCoordinates}
              handleSelectServiceCenter={handleSelectServiceCenter}
            />
          )}
          <div className={cx('map__wrapper')}>
            <ServiceCenterMap
              handleMapInstance={handleMapInstance}
              ymapCoordinates={ymapCoordinates}
              markers={markers}
              handleSelectServiceCenter={handleSelectServiceCenter}
              selectedCenter={selectedCenter}
            />
          </div>
        </div>
        <div className={cx('buttons-group', 'buttons-group--list', 'buttons-group--fixed')}>
          <Button
            className={cx('button', 'list__button-add')}
            variant="contained"
            onClick={() => handleAddServiceCenter()}
            disabled={!selectedCenter}
          >
            Выбрать сервис
          </Button>
        </div>
      </div>
      <ErrorModal
        description={errorText}
        btnTitle="Закрыть"
        btnAction={() => {
          if (errorText === TOKEN_ERROR) {
            dispatch(setVisibleErrorModal(false));
            dispatch(clearTokenData());
            dispatch(serviceCentersApi.util.resetApiState());
            navigate('/auth');
          } else {
            dispatch(setVisibleErrorModal(false));
          }
        }}
      />
    </div>
  );
};

export default SelectServiceCenterPage;
