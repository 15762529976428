import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';

import logo from '../../../assets/images/logo.png';
import iconExit from '../../../assets/icons/icon_exit.svg';

import { ModalWindow } from '../../../shared/ui';

import { NAV_ITEMS } from '../../config/constants';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface IHeaderProps {
  logOut: () => void
}

const Header: React.FC<IHeaderProps> = ({ logOut }) => {
  const { pathname } = useLocation();
  const [visibleExitModal, setVisibleExitModal] = useState<boolean>(false);
  return (
    pathname === '/auth' ? (
      <header className={cx('header')}>
        <div className={cx('container', 'header__container')}>
          <div className={cx('header__logo')}>
            <Link to={'/'}>
              <img src={logo} alt="Service me" />
            </Link>
          </div>
        </div>
      </header>
    ) : (
      <>
        <header className={cx('header')}>
          <div className={cx('container', 'header__container')}>
            <div className={cx('header__logo')}>
              <Link to={'/'}>
                <img src={logo} alt="Service me" />
              </Link>
            </div>
            <button
              className={cx('button--exit')}
              onClick={() => {
                setVisibleExitModal(true);
              }}
            >
              <img src={iconExit} alt="Выйти" />
            Выйти
            </button>
            {visibleExitModal && (
              <ModalWindow
                title="Выход из аккаунта"
                description="Вы действительно хотите выйти из аккаунта?
                  Для записи на сервис или мойку потребуется заново авторизоваться"
                firstBtnTitle="Выйти"
                secondBtnTitle="Отмена"
                firstBtnAction={logOut}
                secondBtnAction={() => setVisibleExitModal(false)}
              />
            )}
          </div>
        </header>
        <nav className={cx('navigation')}>
          <ul className={cx('container')}>
            {NAV_ITEMS.map((item) => (
              <li key={item.id}>
                <NavLink
                  to={item.pathname}
                  className={({ isActive }) => (isActive ? cx('active') : '')}
                >
                  <img src={item.icon} alt="" />
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </>
    )
  );
};

export default Header;
