import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { IService, IServiceOption } from '../../config/interfaces';

import iconChecked from '../../../assets/icons/icon_checked.svg';
import iconPlusWhite from '../../../assets/icons/icon_plus_white.svg';

import { OptionDiagnosticItem } from '../index';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface ISelectedOptions {
  [serviceId: number]: number[];
}

interface IServiceDiagnosticCardProps {
  service: IService
  isSelected: boolean
  setSelectedServiceIds: React.Dispatch<React.SetStateAction<number[]>>
  setSelectedDiagnosticOptions: (arg: ISelectedOptions) => void
}

const ServiceDiagnosticCard: React.FC<IServiceDiagnosticCardProps> = ({
  service,
  isSelected,
  setSelectedServiceIds,
  setSelectedDiagnosticOptions,
}) => {
  const {
    id,
    name,
    options,
  } = service;

  const [isVisibleType, setIsVisibleType] = useState<boolean>(false);
  const [selectedOptionIds, setSelectedOptionIds] = useState<number[]>([]);

  const handleOptionChange = (
    optionId: number,
    isChecked: boolean,
  ) => {
    if (isChecked) {
      setSelectedOptionIds((prev) => [...prev, optionId]);
    } else {
      setSelectedOptionIds((prev) => prev.filter((item) => item !== optionId));
    }
  };

  useEffect(() => {
    setSelectedDiagnosticOptions({
      [id]: selectedOptionIds,
    });
  }, [selectedOptionIds]);

  useEffect(() => {
    if (selectedOptionIds.length === 0) {
      setSelectedServiceIds((prev) => prev.filter(
        (item) => item !== id,
      ));
    }
  }, [selectedOptionIds]);

  const handleClick = (serviceId: number) => {
    setSelectedServiceIds((prev: number[]) => {
      if (prev.includes(serviceId)) {
        return prev.filter((item) => item !== serviceId);
      } else {
        return [...prev, serviceId];
      }
    });
  };

  const selectServiceClick = (serviceId: number) => {
    setSelectedServiceIds((prev: number[]) => {
      if (prev.includes(serviceId)) {
        return prev;
      } else {
        return [...prev, serviceId];
      }
    });
  };

  const getAmount = (arr: IServiceOption[]) => {
    const filteredArr = arr.filter((item) => selectedOptionIds.includes(item.id));

    return filteredArr.reduce((total, item) => total + item.total_price, 0);
  };

  return (
    <div className={cx('services__item', { selected: isSelected })}>
      <div
        className={cx('services__item--header')}
        onClick={() => {
          setIsVisibleType(!isVisibleType);
        }}
        role="button"
        tabIndex={0}
      >
        <div className={cx('services__item--name')}>{name}</div>
        <button
          className={cx('button-expand', { 'button-expand--open': isVisibleType })}
        />
      </div>
      {isVisibleType && (
        <>
          {options.map((option) => (
            <OptionDiagnosticItem
              key={option.id}
              option={option}
              serviceId={id}
              selectedOptionIds={selectedOptionIds}
              onOptionChange={handleOptionChange}
              selectService={() => { selectServiceClick(id); }}
            />
          ))}
        </>
      )}
      <div className={cx('services__item--price-wrapper')}>
        <div className={cx('services__item--price')}>
          {`${getAmount(options).toLocaleString('ru-RU')} ₽`}
        </div>
        {options.length !== 1 && (
          <div className={cx('services__item--price-options')}>Доступны варианты</div>
        )}
      </div>
      <div className={cx('cars__item--buttons')}>
        {isSelected ? (
          <button
            className={cx('button--xs', 'button--xs-selected')}
            onClick={() => handleClick(id)}
          >
            Выбрано
            <img src={iconChecked} alt="" />
          </button>
        ) : (
          <button
            className={selectedOptionIds.length === 0
              ? cx('button--xs', 'button--xs-disabled')
              : cx('button--xs')}
            onClick={() => handleClick(id)}
            disabled={selectedOptionIds.length === 0}
          >
            Выбрать
            <img src={iconPlusWhite} alt="" />
          </button>
        )}
      </div>
    </div>
  );
};

export default ServiceDiagnosticCard;
