import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Button, TextField } from '@mui/material';

import arrowLeft from '../../assets/icons/icon_arrow_left.svg';

import { autoApi, useAddRunMutation, useGetCurrentCarQuery } from '../../app/api/autoApi';

import { ErrorModal } from '../../shared/ui';
import { TOKEN_ERROR } from '../../shared/config/constants';
import { setVisibleErrorModal } from '../../shared/store/slices/globalSlice';
import { clearTokenData } from '../../shared/store/slices/authSlice';
import { useAppDispatch } from '../../shared/config/hooks';

import styles from './styles.module.scss';

import { IFormFields } from './config/interfaces';

const cx = classNames.bind(styles);

interface ISubmitForm {
  mileage: number
}

const AddRunPage: React.FC = () => {
  const [errorText, setErrorText] = useState<string>('');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const methods = useForm<IFormFields>({
    defaultValues:
      {
        mileage: 0,
      },
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
  } = methods;

  const handleError = (error: unknown) => {
    if (error && typeof error === 'object' && 'data' in error) {
      const errorData = (error as { data: { errors?: { detail?: string }[] } }).data;
      if (Array.isArray(errorData.errors) && errorData.errors[0]?.detail) {
        setErrorText(errorData.errors[0].detail);
        dispatch(setVisibleErrorModal(true));
      }
    }
  };

  const { data: carData, error: carDataError } = useGetCurrentCarQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (carData && carData.mileage) {
      reset({
        ...carData,
        mileage: carData.mileage,
      });
    }
    if (carDataError) {
      handleError(carDataError);
    }
  }, [carData, carDataError]);

  const [addRun, { isSuccess, error }] = useAddRunMutation({});

  useEffect(() => {
    if (isSuccess) {
      navigate('/');
    }
    if (error) {
      if (typeof error === 'object' && 'data' in error) {
        const errorData = error.data as { errors?: { detail?: string }[] };
        if (Array.isArray(errorData.errors) && errorData.errors[0]?.detail) {
          setErrorText(errorData.errors[0].detail);
        }
      }
    }
  }, [isSuccess, error]);

  const onSubmit: SubmitHandler<ISubmitForm> = (data) => {
    addRun({
      ...carData,
      mileage: Number(data.mileage),
      modification_id: carData?.modification?.id,
    });
  };

  return (
    <div className={cx('container', 'full-height')}>
      <div className={cx('add-run__container')}>
        <form onSubmit={handleSubmit(onSubmit)} className={cx('form', 'full-height')}>
          <Link to={'/'} className={cx('link-goto')}>
            <img src={arrowLeft} alt="Назад в гараж" />
            Назад в гараж
          </Link>
          <div className={cx('heading')}>
            <h2>{`${carData?.modification?.make || ''} ${carData?.modification?.model || ''}`}</h2>
            <div className={cx('h3')}>Укажите пробег</div>
          </div>
          <div className={cx('form__row')}>
            <Controller
              name="mileage"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Обязательное поле',
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Только цифры',
                },
              }}
              render={({ field, fieldState: { error: errorField } }) => (
                <TextField
                  id="mileage"
                  label="Пробег в км"
                  variant="outlined"
                  error={!!errorField}
                  helperText={errorField ? errorField.message : null}
                  {...field}
                  className={cx('form__input')}
                />
              )}
            />
            {errorText && (
              <div className={cx('error')}>
                {errorText}
              </div>
            )}
          </div>
          <div className={cx('form__row', 'form__row--buttons')}>
            <Button
              variant="contained"
              type="submit"
              className={cx('button')}
              disabled={watch('mileage') === carData?.mileage}
            >
              Сохранить пробег
            </Button>
          </div>
        </form>
      </div>
      <ErrorModal
        description={errorText}
        btnTitle="Закрыть"
        btnAction={() => {
          if (errorText === TOKEN_ERROR) {
            dispatch(setVisibleErrorModal(false));
            dispatch(clearTokenData());
            dispatch(autoApi.util.resetApiState());
            navigate('/auth');
          } else {
            dispatch(setVisibleErrorModal(false));
          }
        }}
      />
    </div>
  );
};

export default AddRunPage;
