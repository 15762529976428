import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

import iconClose from '../../../assets/icons/icon_close.svg';

import { IModificationOption, IOption } from '../../../features/AddNewAutoManual/config/interface';

import { SearchField } from '../../../features';

import { EFieldTypes } from '../../config/enums';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface IRadioGroupWindowProps {
  title: string
  mainBtnTitle: string
  mainBtnAction: () => void
  onCloseBtnAction: () => void
  options: IOption[] | IModificationOption[]
  plateNumberIsInvalid: boolean
  field: any
  onSelect: ({ selectedName, selectedId }: {selectedName: EFieldTypes, selectedId: string}) => void
}

const RadioGroupWindow: React.FC<IRadioGroupWindowProps> = ({
  title,
  mainBtnTitle,
  mainBtnAction,
  onCloseBtnAction,
  options,
  plateNumberIsInvalid,
  field,
  onSelect,
}) => {
  const [search, setSearch] = useState('');
  const [filteredList, setFilteredList] = useState<IOption[] | IModificationOption[]>(options);

  const StyledFormControlLabel = styled(FormControlLabel)({
    width: '100%',
    justifyContent: 'space-between',
    margin: '0',
    padding: '16px 0',
    borderBottom: '1px solid #ddd',
    '&:last-child': {
      borderBottom: 'none',
    },
    '.MuiFormControlLabel-label': {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      color: '#222',
    },
  });

  useEffect(() => {
    if (search) {
      if ((options as IOption[])) {
        setFilteredList(
          (options as IOption[]).filter(
            (option) => option.label.toLowerCase().includes(search.toLowerCase()),
          ),
        );
      } else {
        setFilteredList(
          (options as IModificationOption[]).filter(
            (option) => option.label.toLowerCase().includes(search.toLowerCase()),
          ),
        );
      }
    } else {
      setFilteredList(options);
    }
  }, [search, options]);

  return (
    <div
      className={cx('background')}
      onClick={onCloseBtnAction}
    >
      <div
        className={cx('modal')}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className={cx('button-close')}
          onClick={onCloseBtnAction}
        >
          <img src={iconClose} alt="Закрыть" />
        </button>
        <div className={cx('container')}>
          <div className={cx('content', 'with-fixed-buttons')}>
            <div className={cx('title')}>{title}</div>
            <SearchField setSearch={setSearch} />
            <RadioGroup
              {...field}
              value={field.value}
              onChange={(e) => {
                const selectedValue = e.target.value;
                field.onChange(selectedValue);
                onSelect({ selectedName: field.name, selectedId: selectedValue });
              }}
            >
              {filteredList.map((option) => (
                <StyledFormControlLabel
                  key={option.id}
                  value={field.name === EFieldTypes.YEAR ? option.label : option.id}
                  control={(
                    <Radio
                      color={'warning'}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 24,
                        },
                      }}
                    />
                  )}
                  label={option.label}
                  disabled={plateNumberIsInvalid}
                  labelPlacement="start"
                />
              ))}
            </RadioGroup>
          </div>
          <div className={cx('buttons', 'buttons-group--fixed')}>
            <button
              className={cx('button', 'button-modal')}
              onClick={mainBtnAction}
            >
              {mainBtnTitle}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RadioGroupWindow;
