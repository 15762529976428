import React from 'react';
import classNames from 'classnames/bind';
import { Button } from '@mui/material';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface IConfirmAutoProps {
  car: {
    type: 'gosnumber' | 'vin'
    title: string
    plateNumber: string
    generation: string
    modification: string
    year: number | string
  }
  onSaveAttempt: () => void
  onSaveCar: () => void
}

const ConfirmAuto: React.FC<IConfirmAutoProps> = ({
  car,
  onSaveAttempt,
  onSaveCar,
}) => {
  return (
    <>
      <div className={cx('found-car')}>
        <div className={cx('found-car__line')}>
          <div className={cx('found-car__line--nomination')}>Авто</div>
          <div className={cx('found-car__line--value')}>{car.title}</div>
        </div>
        <div className={cx('found-car__line')}>
          <div className={cx('found-car__line--nomination')}>Госномер</div>
          <div className={cx('found-car__line--value')}>{car.plateNumber}</div>
        </div>
        {car.generation && (
          <div className={cx('found-car__line')}>
            <div className={cx('found-car__line--nomination')}>Поколение</div>
            <div className={cx('found-car__line--value')}>{car.generation}</div>
          </div>
        )}
        {car.modification && (
          <div className={cx('found-car__line')}>
            <div className={cx('found-car__line--nomination')}>Модификация</div>
            <div className={cx('found-car__line--value')}>{car.modification}</div>
          </div>
        )}
        {car.year && (
          <div className={cx('found-car__line')}>
            <div className={cx('found-car__line--nomination')}>Год выпуска</div>
            <div className={cx('found-car__line--value')}>{car.year}</div>
          </div>
        )}
      </div>
      <div className={cx('form__row', 'only-mobile')}>
        <Button
          type="button"
          variant="contained"
          onClick={() => onSaveAttempt()}
          className={cx('button', 'button-text', 'button--add', 'w100')}
        >Это не мое авто
        </Button>
      </div>
      <div className={cx('form__row', 'form__row--buttons')}>
        <Button
          type="button"
          variant="contained"
          onClick={() => onSaveCar()}
          className={cx('button', 'button--add')}
        >Добавить
        </Button>
        <Button
          type="button"
          variant="outlined"
          onClick={() => onSaveAttempt()}
          className={cx('button', 'button-border', 'button--add', 'only-desktop')}
        >Это не мое авто
        </Button>
      </div>
    </>
  );
};

export default ConfirmAuto;
