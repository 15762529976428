import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_API_URL;

interface RootState {
  auth: {
    token: string;
  };
}

export const serviceApi = createApi({
  reducerPath: 'serviceApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;
      headers.set('Content-Type', 'application/json');
      headers.set('Authorization', `Token ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getServicesList: builder.query<any, any>({
      query: (id) => `api/services/?car_id=${id}`,
    }),
  }),
});

export const {
  useGetServicesListQuery,
} = serviceApi;
