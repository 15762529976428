import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_API_URL;

interface RootState {
  auth: {
    token: string;
  };
}

export const autoApi = createApi({
  reducerPath: 'autoApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;
      headers.set('Content-Type', 'application/json');
      headers.set('Authorization', `Token ${token}`);
      return headers;
    },
  }),
  tagTypes: ['cars'],
  endpoints: (builder) => ({
    getAutoBrands: builder.query<any, any>({
      query: () => 'api/auto-catalog/makes/',
    }),
    getAutoBrandModels: builder.query<any, any>({
      query: (args) => ({
        url: `api/auto-catalog/models/?make_id=${args.id}`,
      }),
    }),
    getAutoModelYears: builder.query<any, any>({
      query: (args) => ({
        url: `api/auto-catalog/models/${args.id}/years/`,
      }),
    }),
    getAutoModifications: builder.query<any, any>({
      query: ({ modelId, year }) => ({
        url: `api/auto-catalog/modifications/?model_id=${modelId}&year=${year}`,
      }),
    }),
    addCar: builder.mutation<any, any>({
      query: (arg) => ({
        url: 'api/cars/',
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: ['cars'],
    }),
    getCars: builder.query<any, any>({
      query: () => 'api/cars/',
      providesTags: ['cars'],
    }),
    getCurrentCar: builder.query<any, any>({
      query: (id) => `api/cars/${id}`,
    }),
    addRun: builder.mutation<any, any>({
      query: (arg) => ({
        url: `api/cars/${arg.id}/`,
        method: 'PUT',
        body: arg,
      }),
      invalidatesTags: ['cars'],
    }),
    addLicensePlate: builder.mutation<any, any>({
      query: (arg) => ({
        url: `api/cars/${arg.id}/`,
        method: 'PUT',
        body: arg,
      }),
      invalidatesTags: ['cars'],
    }),
    searchAuto: builder.mutation<any, any>({
      query: (arg) => ({
        url: 'api/auto-catalog/search/',
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: ['cars'],
    }),
    deleteCar: builder.mutation<any, any>({
      query: (id) => ({
        url: `api/cars/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['cars'],
    }),
  }),
});

export const {
  useGetAutoBrandsQuery,
  useGetAutoBrandModelsQuery,
  useGetAutoModelYearsQuery,
  useGetAutoModificationsQuery,
  useGetCarsQuery,
  useGetCurrentCarQuery,
  useAddCarMutation,
  useAddRunMutation,
  useAddLicensePlateMutation,
  useSearchAutoMutation,
  useDeleteCarMutation,
} = autoApi;
