import React from 'react';
import classNames from 'classnames/bind';

import { EWorkTypes } from '../../config/enums';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface IOptionDiagnosticItemProps {
  serviceId: number
  option: {
    id: number
    quality_level: EWorkTypes
    total_price: number
    works: {
      quantity: number
      price:	number
      work:	{
        name: string
        unit_price: number
      }
    }[]
    spare_parts: {
      name: string
      price: number
      spare_part: {
        quantity: number
        name: string
        unit_price: string
        make: string,
        part_number: string
        provider: string
      }
    }[]
  }
  selectedOptionIds: number[]
  onOptionChange: (optionId: number, isChecked: boolean) => void;
  selectService: () => void
}

const OptionDiagnosticItem: React.FC<IOptionDiagnosticItemProps> = ({
  serviceId,
  option,
  selectedOptionIds,
  onOptionChange,
  selectService,
}) => {
  return (
    <div className={cx('option')}>
      <div className={cx('radiobutton-wrapper')}>
        <input
          type="checkbox"
          value={option.id}
          name={`service-${serviceId}`}
          id={`option-${option.id}`}
          onChange={(e) => {
            onOptionChange(Number(e.target.value), e.target.checked);
            selectService();
          }}
          checked={selectedOptionIds.includes(option.id)}
        />
        <label htmlFor={`option-${option.id}`}>
          <span className={cx('label-text')}>
            <span className={cx('option--title')}>{option?.works[0]?.work?.name}</span>
            <span className={cx('option--price')}>{`${option.total_price.toLocaleString('ru-RU')} ₽`}</span>
          </span>
        </label>
      </div>
    </div>
  );
};

export default OptionDiagnosticItem;

