import React from 'react';
import {
  Clusterer,
  GeolocationControl,
  Map,
  Placemark,
  YMaps,
  ZoomControl,
} from '@pbe/react-yandex-maps';

import iconPinActive from '../../../../assets/icons/icon_pin_active.svg';
import iconPin from '../../../../assets/icons/icon_pin.svg';

interface IServiceCenterMapProps {
    handleMapInstance: (arg: any) => void;
    ymapCoordinates: number[]
    markers: any[]
    handleSelectServiceCenter: (arg: string) => void
    selectedCenter: string | null
}

const ServiceCenterMap: React.FC<IServiceCenterMapProps> = ({
  handleMapInstance,
  ymapCoordinates,
  markers,
  handleSelectServiceCenter,
  selectedCenter,
}) => {
  return (
    <div>
      <YMaps>
        <Map
          instanceRef={handleMapInstance}
          defaultState={{ center: ymapCoordinates, zoom: 13, controls: [] }}
          width={'100%'}
          height={'80%'}
        >
          <GeolocationControl
            options={{
              position: { bottom: 24, right: 24 },
            }}
          />
          <ZoomControl
            options={{
              position: { right: 24, top: 24 },
              size: 'small',
            }}
          />
          <Clusterer
            options={{
              gridSize: 128,
              preset: 'islands#invertedBlackClusterIcons',
              groupByCoordinates: false,
            }}
          >
            {markers.map((marker: any) => (
              <Placemark
                key={marker.id}
                defaultGeometry={marker.coordinates}
                onClick={() => handleSelectServiceCenter(marker.id)}
                options={{
                  iconLayout: 'default#image',
                  iconImageHref: selectedCenter === marker.id ? iconPinActive : iconPin,
                  iconImageSize: [40, 56],
                  iconImageOffset: [-20, -56],
                }}
              />
            ))}
          </Clusterer>
        </Map>
      </YMaps>
    </div>
  );
};

export default ServiceCenterMap;
