import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { setupListeners } from '@reduxjs/toolkit/query';

import { authApi } from '../../app/api/authApi';
import { logOutApi } from '../../app/api/logOutApi';
import { userApi } from '../../app/api/userApi';
import { autoApi } from '../../app/api/autoApi';
import { serviceApi } from '../../app/api/serviceApi';
import { serviceCentersApi } from '../../app/api/serviceCentersApi';
import { orderApi } from '../../app/api/orderApi';

import { authSlice } from './slices/authSlice';
import { globalSlice } from './slices/globalSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'global'],
};

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  global: globalSlice.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [logOutApi.reducerPath]: logOutApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [autoApi.reducerPath]: autoApi.reducer,
  [serviceApi.reducerPath]: serviceApi.reducer,
  [serviceCentersApi.reducerPath]: serviceCentersApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(
      authApi.middleware,
      logOutApi.middleware,
      userApi.middleware,
      autoApi.middleware,
      serviceApi.middleware,
      serviceCentersApi.middleware,
      orderApi.middleware,
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
