import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import {
  Button,
  TextField,
  Autocomplete,
} from '@mui/material';

import { regexPlateNumber } from '../../shared/config/regexConfig';

import {
  useAddCarMutation,
  useGetAutoBrandModelsQuery,
  useGetAutoBrandsQuery,
  useGetAutoModelYearsQuery,
  useGetAutoModificationsQuery,
} from '../../app/api/autoApi';

import { ErrorModal, RadioGroupWindow } from '../../shared/ui';

import { setVisibleErrorModal } from '../../shared/store/slices/globalSlice';

import { useAppDispatch } from '../../shared/config/hooks';

import { EFieldTypes } from '../../shared/config/enums';

import { IModificationOption, IModificationRequest, IOption } from './config/interface';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface ISubmitData {
  brand: string
  model: string
  modification: string
  plateNumber: string
  year: string
}

interface ISubmitRequest {
  year: number
  license_plate: string
  modification_id: number
}

const AddNewAutoManual: React.FC = () => {
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      plateNumber: '',
      brand: '',
      model: '',
      year: '',
      modification: '',
    },
  });

  const {
    control,
    handleSubmit,
    getFieldState,
    formState,
    watch,
    setValue,
  } = methods;

  const [optionsBrand, setOptionsBrand] = useState<Array<IOption>>([]);
  const [optionsModel, setOptionsModel] = useState<Array<IOption>>([]);
  const [optionsYears, setOptionYears] = useState<Array<IOption>>([]);
  const [optionsModification, setOptionsModification] = useState<Array<IModificationOption>>([]);
  const [errorText, setErrorText] = useState<string>('');
  const [isVisibleBrandModal, setIsVisibleBrandModal] = useState<boolean>(false);
  const [isVisibleModelModal, setIsVisibleModelModal] = useState<boolean>(false);
  const [isVisibleYearModal, setIsVisibleYearModal] = useState<boolean>(false);
  const [isVisibleModificationModal, setIsVisibleModificationModal] = useState<boolean>(false);

  const isMobile: boolean = window.innerWidth <= 768;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { invalid: plateNumberIsInvalid } = getFieldState('plateNumber', formState);

  const { data: brandList } = useGetAutoBrandsQuery({});

  useEffect(() => {
    if (brandList) {
      setOptionsBrand(brandList.map((brand: {id: number, name: string}) => ({
        id: brand.id,
        value: brand.name,
        label: brand.name,
      })));
    }
  }, [brandList]);

  const { data: modelList } = useGetAutoBrandModelsQuery(
    { id: watch('brand') },
    { skip: !watch('brand') },
  );

  useEffect(() => {
    if (modelList) {
      setOptionsModel(modelList.map((model: {id: number, name: string}) => ({
        id: model.id,
        value: model.name,
        label: model.name,
      })));
    }
  }, [modelList]);

  const { data: yearList } = useGetAutoModelYearsQuery(
    { id: watch('model') },
    { skip: !watch('model') },
  );

  useEffect(() => {
    if (yearList) {
      setOptionYears(yearList.map((year: number, index: number) => ({
        id: index,
        value: year,
        label: year,
      })));
    }
  }, [yearList]);

  const { data: modificationList } = useGetAutoModificationsQuery(
    {
      modelId: watch('model'),
      year: watch('year'),
    },
    { skip: !watch('model') || !watch('year') },
  );

  useEffect(() => {
    if (modificationList) {
      setOptionsModification(modificationList.map((modification: IModificationRequest) => ({
        ...modification,
        label: `${modification.modification}, ${modification.generation}`,
      })));
    }
  }, [modificationList]);

  const [addCar, { isSuccess, error: errorAddCar }] = useAddCarMutation({});

  useEffect(() => {
    if (isSuccess) {
      navigate('/');
    }
    if (errorAddCar) {
      if (typeof errorAddCar === 'object' && 'data' in errorAddCar) {
        const errorData = errorAddCar.data as { errors?: { detail?: string }[] };
        if (Array.isArray(errorData.errors) && errorData.errors[0]?.detail) {
          setErrorText(errorData.errors[0].detail);
          dispatch(setVisibleErrorModal(true));
        }
      }
    }
  }, [isSuccess, errorAddCar]);

  const handleOptionSelect = (
    { selectedName, selectedId }: {
        selectedName: EFieldTypes,
        selectedId: string
    },
  ) => {
    setValue(selectedName, selectedId);
  };

  const onSubmit: SubmitHandler<ISubmitData> = (data) => {
    const request: ISubmitRequest = {
      year: Number(data.year),
      license_plate: data.plateNumber,
      modification_id: Number(data.modification),
    };
    addCar(request);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cx('form', 'full-height')}>
      <div className={cx('form__row')}>
        <Controller
          name={'plateNumber'}
          control={control}
          rules={{
            pattern: {
              value: regexPlateNumber,
              message: 'Некорректный гос номер',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              value={field.value || ''}
              label="Госномер"
              variant="outlined"
              placeholder="X777XX77"
              autoComplete="off"
              error={!!error}
              helperText={error ? error.message : null}
              className={cx('form__input')}
              onChange={(e) => {
                const newValue = e.target.value;
                // eslint-disable-next-line max-len
                const filteredValue = newValue.replace(/[^авекмнорстух0-9]/gi, '');
                if (filteredValue !== newValue) {
                  field.onChange(filteredValue.toUpperCase());
                } else {
                  field.onChange(newValue.toUpperCase());
                }
              }}
            />
          )}
        />
      </div>
      <div className={cx('form__row')}>
        <Controller
          name="brand"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Выберите марку авто',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            isMobile && isVisibleBrandModal ? (
              <RadioGroupWindow
                title="Выберите марку"
                mainBtnTitle="Выбрать"
                mainBtnAction={() => { setIsVisibleBrandModal(false); }}
                onCloseBtnAction={() => { setIsVisibleBrandModal(false); }}
                options={optionsBrand}
                plateNumberIsInvalid={plateNumberIsInvalid}
                field={field}
                onSelect={handleOptionSelect}
              />
            ) : (
              <Autocomplete
                options={optionsBrand}
                getOptionLabel={(option) => option.label}
                onChange={(_, data) => {
                  field.onChange(data?.id || '');
                }}
                value={optionsBrand.find((option) => option.id === Number(field.value)) || null}
                isOptionEqualToValue={(option, value) => option.id === Number(value)}
                noOptionsText="Нет опций"
                disabled={plateNumberIsInvalid}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Марка"
                    placeholder="Поиск марки"
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={cx('form__input')}
                    onFocus={() => {
                      if (isMobile) {
                        setIsVisibleBrandModal(true);
                      }
                    }}
                  />
                )}
              />
            )

          )}
        />
      </div>
      <div className={cx('form__row')}>
        <Controller
          name="model"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Выберите модель авто',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            isMobile && isVisibleModelModal ? (
              <RadioGroupWindow
                title="Выберите модель"
                mainBtnTitle="Выбрать"
                mainBtnAction={() => { setIsVisibleModelModal(false); }}
                onCloseBtnAction={() => { setIsVisibleModelModal(false); }}
                options={optionsModel}
                plateNumberIsInvalid={plateNumberIsInvalid}
                field={field}
                onSelect={handleOptionSelect}
              />
            ) : (
              <Autocomplete
                options={optionsModel}
                getOptionLabel={(option) => option.label}
                onChange={(_, data) => {
                  field.onChange(data?.id || '');
                }}
                value={optionsModel.find((option) => option.id === Number(field.value)) || null}
                isOptionEqualToValue={(option, value) => option.id === Number(value)}
                noOptionsText="Нет опций"
                disabled={!watch('brand')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Модель"
                    placeholder="Поиск модели"
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={cx('form__input')}
                    onFocus={() => {
                      if (isMobile) {
                        setIsVisibleModelModal(true);
                      }
                    }}
                  />
                )}
              />
            )
          )}
        />
      </div>
      <div className={cx('form__row')}>
        <Controller
          name="year"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Выберите год выпуска авто',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            isMobile && isVisibleYearModal ? (
              <RadioGroupWindow
                title="Выберите год выпуска авто"
                mainBtnTitle="Выбрать"
                mainBtnAction={() => { setIsVisibleYearModal(false); }}
                onCloseBtnAction={() => { setIsVisibleYearModal(false); }}
                options={optionsYears}
                plateNumberIsInvalid={plateNumberIsInvalid}
                field={field}
                onSelect={handleOptionSelect}
              />
            ) : (
              <Autocomplete
                options={optionsYears}
                getOptionLabel={(option) => option.label}
                onChange={(_, data) => {
                  field.onChange(data?.value || '');
                }}
                value={optionsYears.find((option) => option.value === Number(field.value)) || null}
                isOptionEqualToValue={(option, value) => option.value === Number(value)}
                noOptionsText="Нет опций"
                disabled={!watch('model')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Год выпуска"
                    placeholder="Год выпуска"
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={cx('form__input')}
                    onFocus={() => {
                      if (isMobile) {
                        setIsVisibleYearModal(true);
                      }
                    }}
                  />
                )}
              />
            )

          )}
        />
      </div>
      <div className={cx('form__row')}>
        <Controller
          name="modification"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Выберите модификацию авто',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            isMobile && isVisibleModificationModal ? (
              <RadioGroupWindow
                title="Выберите модификацию"
                mainBtnTitle="Выбрать"
                mainBtnAction={() => { setIsVisibleModificationModal(false); }}
                onCloseBtnAction={() => { setIsVisibleModificationModal(false); }}
                options={optionsModification}
                plateNumberIsInvalid={plateNumberIsInvalid}
                field={field}
                onSelect={handleOptionSelect}
              />
            ) : (
              <Autocomplete
                options={optionsModification}
                getOptionLabel={(option) => option.label}
                onChange={(_, data) => field.onChange(data?.id || '')}
                value={optionsModification.find((option) => option.id === Number(field.value)) || null}
                isOptionEqualToValue={(option, value) => option.id === Number(value)}
                noOptionsText="Нет опций"
                disabled={!watch('year')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Модификация"
                    placeholder="Поиск модификации"
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={cx('form__input')}
                    onFocus={() => {
                      if (isMobile) {
                        setIsVisibleModificationModal(true);
                      }
                    }}
                  />
                )}
              />
            )
          )}
        />
      </div>
      <div className={cx('form__row', 'form__row--buttons')}>
        <Button
          variant="contained"
          type="submit"
          disabled={!formState.isValid}
          className={cx('button', 'button--add')}
        >
            Добавить
        </Button>
      </div>
      <ErrorModal
        description={errorText}
        btnTitle="Закрыть"
        btnAction={() => {
          dispatch(setVisibleErrorModal(false));
        }}
      />
    </form>
  );
};

export default AddNewAutoManual;
