import React, { useState } from 'react';

import { EAuthSteps } from './config/enums';
import { ConfirmStep, PhoneStep } from './ui';

const Authorization: React.FC = () => {
  const [step, setStep] = useState<EAuthSteps>(EAuthSteps.phoneStep);
  const [phoneNumber, setPhoneNumber] = useState<string>('+71231231234');

  return (
    step === EAuthSteps.phoneStep ? (
      <PhoneStep
        toNextStep={() => setStep(EAuthSteps.confirmStep)}
        setPhoneNumber={setPhoneNumber}
      />
    ) : (
      <ConfirmStep
        goBack={() => setStep(EAuthSteps.phoneStep)}
        phoneNumber={phoneNumber}
      />
    )
  );
};

export default Authorization;
