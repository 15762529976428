import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const NotFoundPage: React.FC = () => {
  return (
    <div className={cx('container', 'full-height')}>
      <div className={cx('not-found__container')}>
        <div className={cx('error-404')}>404</div>
        <h2>Страница не найдена</h2>
        <div className={cx('h3')}>Перейдите на <Link to="/">главую страницу</Link></div>
      </div>
    </div>
  );
};

export default NotFoundPage;
