import React from 'react';
import classNames from 'classnames/bind';

import iconClose from '../../../assets/icons/icon_close.svg';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface IModalWindowProps {
  title: string
  description: string
  firstBtnTitle: string
  secondBtnTitle: string
  firstBtnAction: () => void
  secondBtnAction: () => void
}

const ModalWindow: React.FC<IModalWindowProps> = ({
  title,
  description,
  firstBtnTitle,
  secondBtnTitle,
  firstBtnAction,
  secondBtnAction,
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={cx('background')}
      onClick={secondBtnAction}
    >
      <div className={cx('modal')}>
        <button
          className={cx('button-close')}
          onClick={secondBtnAction}
        >
          <img src={iconClose} alt="Закрыть" />
        </button>
        <div className={cx('container')}>
          <div className={cx('content')}>
            <div className={cx('title')}>{title}</div>
            <div className={cx('description')}>{description}</div>
          </div>
          <div className={cx('buttons')}>
            <button
              className={cx('button', 'button-border', 'button-modal')}
              onClick={firstBtnAction}
            >
              {firstBtnTitle}
            </button>
            <button
              className={cx('button', 'button-modal')}
              onClick={secondBtnAction}
            >
              {secondBtnTitle}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalWindow;
