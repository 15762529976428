import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import iconClose from '../../../assets/icons/icon_close.svg';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const InstallPopup: React.FC = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any | null>(null);
  const [showInstallPopup, setShowInstallPopup] = useState(false);

  useEffect(() => {
    const displayTime = localStorage.getItem('displayTime');

    const handleBeforeInstallPrompt = (e: any) => {
      e.preventDefault();
      // eslint-disable-next-line no-console
      console.log('Событие beforeinstallprompt сработало');
      setDeferredPrompt(e);
      if (!displayTime) {
        setShowInstallPopup(true);
      }
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  }, []);

  const saveTimestamp = () => {
    const timestamp = Date.now();
    localStorage.setItem('displayTime', timestamp.toString());
  };

  const handleInstall = async (e: any) => {
    e.preventDefault();

    if (deferredPrompt) {
      // eslint-disable-next-line no-console
      console.log('Вызываем prompt для установки PWA');
      deferredPrompt.prompt();

      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === 'accepted') {
        // eslint-disable-next-line no-console
        console.log('Пользователь установил приложение');
      } else {
        // eslint-disable-next-line no-console
        console.log('Пользователь отказался от установки');
      }

      setDeferredPrompt(null);
      setShowInstallPopup(false);
    } else {
      // eslint-disable-next-line no-console
      console.log('deferredPrompt не доступен');
    }
  };

  const handleCancel = () => {
    saveTimestamp();
    setShowInstallPopup(false);
  };

  return (
    showInstallPopup ? (
      <div
        className={cx('background')}
        onClick={handleCancel}
      >
        <div className={cx('modal')}>
          <button
            className={cx('button-close')}
            onClick={handleCancel}
          >
            <img src={iconClose} alt="Закрыть" />
          </button>
          <div className={cx('container')}>
            <div className={cx('content')}>
              <div className={cx('title')}>Установка приложения</div>
              <div className={cx('description')}>Хотите установить приложение?</div>
            </div>
            <div className={cx('buttons')}>
              <button
                className={cx('button', 'button-border', 'button-modal')}
                onClick={handleInstall}
              >
                Установить
              </button>
              <button
                className={cx('button', 'button-modal')}
                onClick={handleCancel}
              >
                Отмена
              </button>
            </div>
          </div>
        </div>
      </div>
    )
      : null
  );
};

export default InstallPopup;
