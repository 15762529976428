import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button } from '@mui/material';

import arrowLeft from '../../assets/icons/icon_arrow_left.svg';

import { IService } from '../../shared/config/interfaces';

import { useAppDispatch } from '../../shared/config/hooks';

import {
  setAmount,
  setSelectServices,
  setServicedCar,
  setVisibleErrorModal,
} from '../../shared/store/slices/globalSlice';

import {
  ErrorModal,
  Loader,
  ServiceCard,
} from '../../shared/ui';

import { serviceApi, useGetServicesListQuery } from '../../app/api/serviceApi';

import { EServiceTypes } from '../../shared/config/enums';

import ServiceDiagnosticCard from '../../shared/ui/ServiceDiagnosticCard';

import { TOKEN_ERROR } from '../../shared/config/constants';
import { clearTokenData } from '../../shared/store/slices/authSlice';

import getFilterSelectedServices from './functions/getFilterSelectedServices';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const ServicesPage: React.FC = () => {
  const [serviceList, setServiceList] = useState<IService[]>([]);
  const [errorText, setErrorText] = useState<string>('');
  const [selectedServiceIds, setSelectedServiceIds] = useState<number[]>([]);
  const [selectedServicesOptions, setSelectedServicesOptions] = useState<{[serviceId: number]: number}>({});
  const [selectedDiagnosticOptions, setSelectedDiagnosticOptions] = useState<{[serviceId: number]: number[]}>({});

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleError = (error: unknown) => {
    if (error && typeof error === 'object' && 'data' in error) {
      const errorData = (error as { data: { errors?: { detail?: string }[] } }).data;
      if (Array.isArray(errorData.errors) && errorData.errors[0]?.detail) {
        setErrorText(errorData.errors[0].detail);
        dispatch(setVisibleErrorModal(true));
      }
    }
  };

  const { data, error, isLoading } = useGetServicesListQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (data) {
      setServiceList(data);
      if (data.some((item: IService) => (
        item.service_type === EServiceTypes.SERVICE_WORK
          || item.service_type === EServiceTypes.MAINTENANCE
          || item.service_type === EServiceTypes.DIAGNOSTIC
      ))) {
        dispatch(setServicedCar(true));
      } else {
        dispatch(setServicedCar(false));
      }
    }
    if (error) {
      handleError(error);
    }
  }, [data, error]);

  const selectedServices = serviceList.filter(
    (service) => (selectedServiceIds.includes(service.id)),
  );

  const filteredServices = getFilterSelectedServices(
    selectedServices,
    selectedServicesOptions,
    selectedDiagnosticOptions,
  );

  const getAmount = (arr: IService[]) => {
    return arr.reduce(
      (total, item) => total
        + item.options.reduce((totalOptions, option) => totalOptions + option.total_price, 0),
      0,
    );
  };

  return (
    <div className={cx('container', 'full-height')}>
      <div className={cx('services__container')}>
        <Link to={'/'} className={cx('link-goto')}>
          <img src={arrowLeft} alt="Назад в гараж" />
          Назад в гараж
        </Link>
        <div className={cx('heading')}>
          <h2>Выберите услуги</h2>
          <div className={cx('h3')}>По ряду услуг вы можете выбрать из нескольких преложенных комплектующих</div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            {serviceList.length !== 0 ? (
              <div className={cx('services__list', 'with-fixed-buttons')}>
                {serviceList.map((service) => (
                  service.service_type === EServiceTypes.DIAGNOSTIC ? (
                    <ServiceDiagnosticCard
                      key={service.id}
                      service={service}
                      isSelected={selectedServiceIds.includes(service.id)}
                      setSelectedServiceIds={setSelectedServiceIds}
                      setSelectedDiagnosticOptions={setSelectedDiagnosticOptions}
                    />
                  ) : (
                    <ServiceCard
                      type={service.service_type}
                      key={service.id}
                      service={service}
                      isSelected={selectedServiceIds.includes(service.id)}
                      setSelectedServiceIds={setSelectedServiceIds}
                      selectedServicesOptions={selectedServicesOptions}
                      setSelectedServicesOptions={setSelectedServicesOptions}
                    />
                  )
                ))}
              </div>
            ) : (
              <div className={cx('h3')}>На данный момент услуги не добавлены</div>
            )}
          </div>
        )}
        <div className={cx('buttons-group', 'buttons-group--total', 'buttons-group--fixed')}>
          <div className={cx('total')}>
            <div className={cx('total__text')}>Итого</div>
            <div className={cx('total__value')}>
              {`${Number(getAmount(filteredServices).toFixed(2)).toLocaleString('ru-RU')} ₽`}
            </div>
          </div>
          <Button
            variant="contained"
            type="submit"
            className={cx('button')}
            onClick={() => {
              dispatch(setSelectServices(filteredServices));
              dispatch(setAmount(Number(getAmount(filteredServices).toFixed(2))));
              navigate('/select-service-center');
            }}
            disabled={!selectedServiceIds.length}
          >
            Добавить
          </Button>
        </div>
      </div>
      <ErrorModal
        description={errorText}
        btnTitle="Закрыть"
        btnAction={() => {
          if (errorText === TOKEN_ERROR) {
            dispatch(setVisibleErrorModal(false));
            dispatch(clearTokenData());
            dispatch(serviceApi.util.resetApiState());
            navigate('/auth');
          } else {
            dispatch(setVisibleErrorModal(false));
          }
        }}
      />
    </div>
  );
};

export default ServicesPage;
