import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import { Header, JivoChat } from '../../../shared/ui';
import { useAppDispatch, useAppSelector } from '../../../shared/config/hooks';
import { clearTokenData, clearUserName } from '../../../shared/store/slices/authSlice';
import { useGetLogOutMutation } from '../../api/logOutApi';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const GlobalLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const token = useAppSelector((state) => state.auth.token);

  const [getLogOut] = useGetLogOutMutation({});

  useEffect(() => {
    if (!token && pathname !== '/landing') {
      navigate('/auth');
    }
  }, [token, navigate]);

  return (
    pathname !== '/landing' ? (
      <>
        <Header
          logOut={() => {
            getLogOut({});
            dispatch(clearTokenData());
            dispatch(clearUserName());
          }}
        />
        <main className={cx('content')}>
          <Outlet />
        </main>
        <JivoChat />
      </>
    ) : (
      <main className={cx('content-landing')}>
        <Outlet />
      </main>
    )
  );
};

export default GlobalLayout;
