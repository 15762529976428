import React from 'react';
import classNames from 'classnames/bind';

import iconClose from '../../../assets/icons/icon_close.svg';

import { useAppSelector } from '../../config/hooks';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface IErrorModalProps {
  description: string
  btnTitle: string
  btnAction: () => void
}

const ErrorModal: React.FC<IErrorModalProps> = ({
  description,
  btnTitle,
  btnAction,
}) => {
  const visibleErrorModal = useAppSelector((state) => state.global.visibleErrorModal);

  return (
    visibleErrorModal ? (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={cx('background')}
        onClick={btnAction}
      >
        <div className={cx('modal')}>
          <button
            className={cx('button-close')}
            onClick={btnAction}
          >
            <img src={iconClose} alt="Закрыть" />
          </button>
          <div className={cx('container')}>
            <div className={cx('content')}>
              <div className={cx('title')}>Упс! Что-то пошло не так...</div>
              <div className={cx('description')}>{description}</div>
            </div>
            <div className={cx('buttons')}>
              <button
                className={cx('button', 'button-modal')}
                onClick={btnAction}
              >
                {btnTitle}
              </button>
            </div>
          </div>
        </div>
      </div>
    ) : null
  );
};

export default ErrorModal;
