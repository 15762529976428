import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import { IAuto } from '../../../../shared/config/interfaces';

import iconPlusWhite from '../../../../assets/icons/icon_plus_white.svg';
import iconChecked from '../../../../assets/icons/icon_checked_white.svg';
import iconPoints from '../../../../assets/icons/icon_points.svg';
import iconEdit from '../../../../assets/icons/icon_edit.svg';
import iconDelete from '../../../../assets/icons/icon_delete.svg';

import { ModalWindow } from '../../../../shared/ui';

import { setAutoId } from '../../../../shared/store/slices/globalSlice';
import { useAppDispatch } from '../../../../shared/config/hooks';

import { useDeleteCarMutation } from '../../../../app/api/autoApi';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface IAutoProps {
  auto: IAuto
  isSelected: boolean
  setSelectedAutoId: (id: number | null) => void
}

const AutoItem: React.FC<IAutoProps> = ({
  auto,
  isSelected,
  setSelectedAutoId,
}) => {
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
  const {
    id,
    modification: {
      make,
      model,
      generation,
      modification,
    },
    year,
    license_plate,
    mileage,
  } = auto;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [deleteCar] = useDeleteCarMutation({});

  const handleClick = () => {
    setSelectedAutoId(isSelected ? null : id);
  };

  return (
    <div className={cx('cars__item', { selected: isSelected })}>
      <button
        className={cx('points')}
        onClick={() => {
          setVisibleModal(true);
          dispatch(setAutoId(id));
        }}
      >
        <img src={iconPoints} alt="" />
      </button>
      <div className={cx('cars__item--year')}>{year}</div>
      <div className={cx('cars__item--name')}>{`${make} ${model}`}</div>
      <div className={cx('cars__item--info')}>
        <div className={cx('cars__item--info-title')}>Пробег</div>
        <div className={cx('cars__item--info-value')}>{mileage || 0}&nbsp;КМ</div>
      </div>
      <div className={cx('cars__item--info')}>
        <div className={cx('cars__item--info-title')}>ГОСНОМЕР</div>
        <div className={cx('cars__item--info-value')}>{license_plate}</div>
      </div>
      {generation && (
        <div className={cx('cars__item--info')}>
          <div className={cx('cars__item--info-title')}>ПОКОЛЕНИЕ</div>
          <div className={cx('cars__item--info-value')}>{generation}</div>
        </div>
      )}
      {modification && (
        <div className={cx('cars__item--info')}>
          <div className={cx('cars__item--info-title')}>МОДИФИКАЦИЯ</div>
          <div className={cx('cars__item--info-value')}>{modification}</div>
        </div>
      )}
      <div className={cx('cars__item--buttons')}>
        {isSelected ? (
          <button
            className={cx('button--xs', 'button--xs-selected')}
            onClick={handleClick}
          >
          Выбрано
            <img src={iconChecked} alt="" />
          </button>
        ) : (
          <button
            className={cx('button--xs')}
            onClick={handleClick}
          >
          Выбрать
            <img src={iconPlusWhite} alt="" />
          </button>
        )}
      </div>
      {visibleModal && (
        <div className={cx('modal')}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className={cx('modal__background')}
            onClick={() => { setVisibleModal(false); }}
          />
          <div className={cx('modal__container')}>
            <div className={cx('modal__content')}>
              <button
                className={cx('button--edit')}
                onClick={() => {
                  setVisibleModal(false);
                  navigate(`/add-run/${id}`);
                }}
              >
                <img src={iconEdit} alt="" />
                  Изменить пробег
              </button>
              <button
                className={cx('button--edit')}
                onClick={() => {
                  setVisibleModal(false);
                  navigate(`/add-license-plate/${id}`);
                }}
              >
                <img src={iconEdit} alt="" />
                  Изменить гос номер
              </button>
              <button
                className={cx('button--delete')}
                onClick={() => {
                  setVisibleModal(false);
                  setVisibleDeleteModal(true);
                }}
              >
                <img src={iconDelete} alt="" />
                      Удалить авто
              </button>
            </div>
          </div>
        </div>
      )}
      {visibleDeleteModal && (
        <ModalWindow
          title="Удаление авто"
          description="Отменить удаление будет невозможно. Для записи на сервис вам потребуется добавить авто заново"
          firstBtnTitle="Удалить"
          secondBtnTitle="Отмена"
          firstBtnAction={() => {
            deleteCar(id);
          }}
          secondBtnAction={() => setVisibleDeleteModal(false)}
        />
      )}
    </div>
  );
};

export default AutoItem;
