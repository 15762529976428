import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import { Button, TextField } from '@mui/material';

import arrowLeft from '../../assets/icons/icon_arrow_left.svg';
import { KRASNODAR_CENTER, TOKEN_ERROR } from '../../shared/config/constants';
import calculateDistance from '../../shared/functions/calculateDistance';

import { serviceCentersApi, useGetLocationServiceCentersQuery } from '../../app/api/serviceCentersApi';

import { setVisibleErrorModal } from '../../shared/store/slices/globalSlice';
import { useAppDispatch } from '../../shared/config/hooks';
import { ErrorModal, ModalWindow } from '../../shared/ui';

import { useCreateCallbackMutation } from '../../app/api/userApi';

import { ECallbackTypes } from '../../shared/config/enums';

import { clearTokenData } from '../../shared/store/slices/authSlice';

import { Card } from './ui';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface ICenter {
  id: string
  name: string
  city: string
  region: string
  settlement: string
  street: string
  house: string
}

const ServiceCentersPage: React.FC = () => {
  const [serviceCenters, setServiceCenters] = useState<object[] | null>(null);
  const [userCoordinates, setUserCoordinates] = useState<number[] | null>(null);
  const [selectedCenter, setSelectedCenter] = useState<ICenter | null>(null);
  const [valueFieldQuestion, setValueFieldQuestion] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleError = (error: unknown) => {
    if (error && typeof error === 'object' && 'data' in error) {
      const errorData = (error as { data: { errors?: { detail?: string }[] } }).data;
      if (Array.isArray(errorData.errors) && errorData.errors[0]?.detail) {
        setErrorText(errorData.errors[0].detail);
        dispatch(setVisibleErrorModal(true));
      }
    }
  };

  const { data: centersData, error } = useGetLocationServiceCentersQuery({});

  const [createCallback, { isSuccess }] = useCreateCallbackMutation({});

  useEffect(() => {
    if (isSuccess) {
      setIsVisibleModal(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    // Запрос на получение геопозиции пользователя
    if (navigator.geolocation && !userCoordinates) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          setUserCoordinates([coords.latitude, coords.longitude]);
        },
        () => {
          // Если не удалось получить геопозицию
          setUserCoordinates(null);
        },
        { enableHighAccuracy: true },
      );
    }
  }, []);

  useEffect(() => {
    if (centersData) {
      setServiceCenters(centersData.map((center: any) => ({
        id: center.id.toString(),
        address: `${center.name}, ${center.settlement}, ${center.street}, ${center.house}`,
        location: calculateDistance(
          userCoordinates || KRASNODAR_CENTER,
          [center.coordinates.latitude, center.coordinates.longitude],
        ),
        openHours: 'с 9:00 до 18:00',
        telegramLink: '',
        whatsappLink: '',
      })));
    }
    if (error) {
      handleError(error);
    }
  }, [centersData, error]);

  const getTextAddress = () => {
    if (selectedCenter) {
      const {
        name, settlement, street, house,
      } = selectedCenter;
      return `${name}, ${settlement}, ${street}, ${house || ''}`;
    } else {
      return '';
    }
  };

  const closeForm = () => {
    setSelectedCenter(null);
  };

  const handleCallMe = (id: string) => {
    if (id) {
      const findedCenter = centersData.find((center: any) => String(center.id) === id);
      setSelectedCenter(findedCenter);
    }
  };

  const handleSubmit = () => {
    createCallback({
      callback_type: ECallbackTypes.SERVICE_CENTER,
      service_center_id: selectedCenter?.id,
      comment: valueFieldQuestion,
    });
  };

  return (
    <div className={cx('container', 'full-height')}>
      <div className={cx('service-center__container')}>
        {selectedCenter ? (
          <div className={cx('service-center__form')}>
            <Button
              className={cx('button-text', 'height-auto')}
              onClick={() => closeForm()}
            >
              <img src={arrowLeft} alt="Назад к списку сервисных центров" />
              Назад к списку сервисных центров
            </Button>
            <div className={cx('heading')}>
              <h2>Перезвоните мне</h2>
              <div className={cx('h3')}>Задайте вопрос и мы перезвоним вам</div>
            </div>
            <div className={cx('service-center__form-description')}>
              <h3>{selectedCenter.name}</h3>
              <p>{getTextAddress()}</p>
            </div>
            <TextField
              value={valueFieldQuestion}
              onChange={(e) => setValueFieldQuestion(e.target.value)}
              label="Ваш вопрос"
              variant="outlined"
              className={cx('form__input')}
              multiline
            />
            <div className={cx('buttons-group')}>
              <Button
                variant="contained"
                onClick={() => handleSubmit()}
                className={cx('button', 'button--send')}
              >
                Отправить заявку
              </Button>
            </div>
          </div>
        ) : (
          <div className={cx('service-center__cards-wrapper')}>
            <div className={cx('heading')}>
              <h2>Список сервисных центров</h2>
            </div>
            <div className={cx('service-center__cards')}>
              {serviceCenters && serviceCenters.map((center: any) => (
                <Card
                  key={center.id}
                  address={center.address}
                  openHours={center.openHours}
                  location={center.location}
                  onCallMe={() => handleCallMe(center.id)}
                  telegramLink="https://t.me/OptimaKubanBot"
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <ErrorModal
        description={errorText}
        btnTitle="Закрыть"
        btnAction={() => {
          if (errorText === TOKEN_ERROR) {
            dispatch(setVisibleErrorModal(false));
            dispatch(clearTokenData());
            dispatch(serviceCentersApi.util.resetApiState());
            navigate('/auth');
          } else {
            dispatch(setVisibleErrorModal(false));
          }
        }}
      />
      {isVisibleModal && (
        <ModalWindow
          title="Успешно"
          description="Ваш запрос отправлен"
          firstBtnTitle="В гараж"
          secondBtnTitle="Закрыть"
          firstBtnAction={() => navigate('/')}
          secondBtnAction={() => setIsVisibleModal(false)}
        />
      )}
    </div>
  );
};

export default ServiceCentersPage;
