import React from 'react';
import classNames from 'classnames/bind';
import { Button } from '@mui/material';

import iconLoading from '../../../../assets/icons/icon_loading.svg';
import iconPlus from '../../../../assets/icons/icon_plus_white.svg';
import iconCheckedWhite from '../../../../assets/icons/icon_checked_white.svg';

import { KRASNODAR_CENTER } from '../../../../shared/config/constants';
import calculateDistance from '../../../../shared/functions/calculateDistance';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const IconPlus = () => <img src={iconPlus} alt="" />;
const IconCheckedWhite = () => <img src={iconCheckedWhite} alt="" />;

interface IServiceCenterListProps {
    selectedCenter: string | null
    handleShowAllCenters: () => void
    showAllCenters: boolean
    centersData: any
    userCoordinates: number[] | null
    handleSelectServiceCenter: (arg: string) => void
}

const ServiceCenterList: React.FC<IServiceCenterListProps> = ({
  selectedCenter,
  handleShowAllCenters,
  showAllCenters,
  centersData,
  userCoordinates,
  handleSelectServiceCenter,
}) => {
  const getDistanceAndSortCenters = (centers: any) => {
    const coordinates = userCoordinates || KRASNODAR_CENTER;
    return centers
      .map((center: any) => ({
        ...center,
        distance: calculateDistance(coordinates, [center.coordinates.latitude, center.coordinates.longitude]),
      }))
      .sort((a: any, b: any) => a.distance - b.distance);
  };

  return (
    <div className={cx('container', 'list')}>
      {selectedCenter && (
        <Button
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            handleShowAllCenters();
          }}
          className={cx('button-text', 'button-text--small')}
          style={{ display: showAllCenters ? 'none' : 'flex' }}
        >
            Показать все
          <img src={iconLoading} alt="Показать все" />
        </Button>
      )}
      <ul className={cx('list__cards')}>
        {centersData && getDistanceAndSortCenters(
          centersData.filter(
            (center: any) => showAllCenters || center.id.toString() === selectedCenter,
          ),
        ).map((center: any) => (
          <li
            key={center.id}
            className={cx('list__card', 'card', {
              card_active: center.id === Number(selectedCenter),
            })}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className={cx('card__title')}>
              {`${center.name}, ${center.settlement}, ${center.street}, ${center.house}`}
            </div>
            <div className={cx('card__open-hours')}>Открыто с 9:00 до 18:00</div>
            <div className={cx('card__distance')}>
              {center.distance.toFixed(2)}&nbsp;км от вас
            </div>
            <Button
              variant="contained"
              className={cx('button--xs', {
                'button--xs-selected': center.id === Number(selectedCenter),
              })}
              endIcon={center.id === Number(selectedCenter) ? <IconCheckedWhite /> : <IconPlus />}
              onClick={(e) => {
                e.stopPropagation();
                handleSelectServiceCenter(String(center.id));
              }}
            >
              {center.id === Number(selectedCenter) ? 'Выбрано' : 'Выбрать'}
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ServiceCenterList;
