import React, { useEffect, useRef, useState } from 'react';
import {
  useForm,
  Controller,
  SubmitHandler,
} from 'react-hook-form';
import classNames from 'classnames/bind';
import { Button, TextField } from '@mui/material';

import { useGetTokenMutation } from '../../../../app/api/authApi';
import { setToken } from '../../../../shared/store/slices/authSlice';
import { useAppDispatch } from '../../../../shared/config/hooks';
import arrowLeft from '../../../../assets/icons/icon_arrow_left.svg';

import { CustomTimer } from '../index';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface ISubmitForm {
  code: string[]
}

interface IConfirmStepProps {
  goBack: () => void
  phoneNumber: string | null
}

const ConfirmStep: React.FC<IConfirmStepProps> = ({ goBack, phoneNumber }) => {
  const dispatch = useAppDispatch();

  const [errorText, setErrorText] = useState<string | null>(null);

  const methods = useForm<ISubmitForm>({
    defaultValues:
      {
        code: ['', '', '', '', '', ''],
      },
  });

  const {
    control,
    handleSubmit,
    watch,
  } = methods;

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    inputRefs.current.forEach((input, index) => {
      if (input && input.value.length === 1 && index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    });
  }, [inputRefs]);

  const [getToken, { data: tokenData, error }] = useGetTokenMutation({});

  useEffect(() => {
    if (tokenData) {
      dispatch(setToken(tokenData.token));
    }
    if (error) {
      if (typeof error === 'object' && 'data' in error) {
        const errorData = error.data as { errors?: { detail?: string }[] };
        if (Array.isArray(errorData.errors) && errorData.errors[0]?.detail) {
          setErrorText(errorData.errors[0].detail);
        }
      }
    }
  }, [tokenData, error]);

  const onSubmit: SubmitHandler<ISubmitForm> = (data) => {
    getToken({
      phone: phoneNumber,
      code: data.code.join(''),
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cx('form', 'full-height')}
      autoComplete="off"
    >
      <Button
        variant="contained"
        onClick={goBack}
        className={cx('button-text', 'button-text--small')}
      >
        <img src={arrowLeft} alt="Назад к вводу номера" />
        Назад к&nbsp;вводу номера
      </Button>
      <div className={cx('heading')}>
        <h2>Введите код</h2>
        <div className={cx('h3')}>
          Код подтверждения отправлен на&nbsp;номер&nbsp;<span className={cx('text-nowrap')}>{phoneNumber}</span>
        </div>
      </div>
      <div className={cx('form__row form__row--space-between')}>
        {[0, 1, 2, 3, 4, 5].map((index) => {
          return (
            <Controller
              key={index}
              name={`code.${index}`}
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Только цифры',
                },
              }}
              defaultValue=""
              render={({ field, fieldState: { error: errorField } }) => (
                <TextField
                  id={`code.${index}`}
                  variant="outlined"
                  margin="normal"
                  error={!!errorField}
                  helperText={errorField ? errorField.message : null}
                  inputProps={{ maxLength: '1' }}
                  {...field}
                  className={cx('form__input', 'form__input--code')}
                  inputRef={(el) => (inputRefs.current[index] = el)}
                  onInput={(e) => {
                    const input = e.target as HTMLInputElement;
                    if (input.value.length === 1 && index < 5) {
                      inputRefs.current[index + 1]?.focus();
                    }
                  }}
                  onKeyDown={(e) => {
                    const input = e.target as HTMLInputElement;
                    if (e.key === 'Backspace' && input.value === '' && index > 0) {
                      inputRefs.current[index - 1]?.focus();
                    }
                  }}
                  type="tel"
                />
              )}
            />
          );
        })}
        {errorText && (
          <div className={cx('error')}>
            {errorText}
          </div>
        )}
      </div>
      <div className={cx('form__row')}>
        <CustomTimer phoneNumber={phoneNumber} />
      </div>
      <div className={cx('form__row', 'form__row--buttons')}>
        <Button
          variant="contained"
          type="submit"
          className={cx('button')}
          disabled={watch('code').some((item) => item === '')}
        >
          Войти
        </Button>
      </div>
    </form>
  );
};

export default ConfirmStep;
