import React from 'react';
import classNames from 'classnames/bind';

import { InputBase } from '@mui/material';

import iconLupa from '../../assets/icons/icon_lupa.svg';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface ISearchFieldProps {
  setSearch: (arg: string) => void
}

const SearchField: React.FC<ISearchFieldProps> = ({ setSearch }) => {
  const handleChange = (event: { target: { value: string; }; }) => {
    setSearch(event.target.value);
  };

  return (
    <form className={cx('search-form')}>
      <div className={cx('input-base__wrapper')}>
        <img src={iconLupa} alt="" />
        <InputBase
          type="search"
          placeholder="Поиск"
          inputProps={{ 'aria-label': 'Поиск' }}
          className={cx('input-base')}
          onChange={handleChange}
        />
        <input
          type="reset"
          className={cx('input-reset')}
        />
      </div>
    </form>
  );
};

export default SearchField;
