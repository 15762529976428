import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_API_URL;

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      return headers;
    },
    responseHandler: async (response) => {
      if (response.status === 401) {
        throw new Error('Unauthorized');
      }
      if (response.status === 403) {
        throw new Error('Wrong place');
      }
      return response.json();
    },
  }),
  endpoints: (builder) => ({
    getSmsCode: builder.mutation<any, any>({
      query: (arg) => ({
        url: '/api/auth/send-sms/',
        method: 'POST',
        body: arg,
      }),
    }),
    getToken: builder.mutation<any, any>({
      query: (arg) => ({
        url: '/api/auth/get-token/',
        method: 'POST',
        body: arg,
      }),
    }),
  }),
});

export const {
  useGetSmsCodeMutation,
  useGetTokenMutation,
} = authApi;
