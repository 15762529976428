import { IService } from '../../../shared/config/interfaces';
import { EServiceTypes } from '../../../shared/config/enums';

import cheapOption from '../../../shared/functions/cheapOption';

const getFilterSelectedServices = (
  services: IService[],
  selections: { [key: number]: number },
  diagnosticSelections: { [key: number]: number[] },
): IService[] => {
  return services.map((service) => {
    const selectedOptions = selections[service.id];
    const selectedDiagnosticOptions = diagnosticSelections[service.id];

    if (service.service_type === EServiceTypes.DIAGNOSTIC && selectedDiagnosticOptions) {
      return {
        ...service,
        options: service.options.filter(
          (option) => selectedDiagnosticOptions.includes(option.id),
        ),
      };
    } else if (selectedOptions !== undefined) {
      return {
        ...service,
        options: service.options.filter(
          (option) => option.id === selectedOptions,
        ),
      };
    } else if (selectedOptions === undefined) {
      return {
        ...service,
        options: [cheapOption(service.options)],
      };
    }
    return service;
  });
};

export default getFilterSelectedServices;
