import iconGarage from '../../assets/icons/icon_garage.svg';
import iconAddresses from '../../assets/icons/icon_addresses.svg';
import iconContacts from '../../assets/icons/icon_contacts.svg';

import { INavItem } from './interfaces';

export const NAV_ITEMS: INavItem[] = [
  {
    id: 0,
    pathname: '/',
    name: 'Гараж',
    icon: iconGarage,
  },
  {
    id: 1,
    pathname: '/service-centers',
    name: 'Адреса СЦ',
    icon: iconAddresses,
  },
  {
    id: 2,
    pathname: '/contacts',
    name: 'Контакты',
    icon: iconContacts,
  },
];

export const KRASNODAR_CENTER: number[] = [45.034538, 38.973957];

export const TOKEN_ERROR: string = 'Недопустимый токен.';
