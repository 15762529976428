import { createSlice } from '@reduxjs/toolkit';

import {
  IGlobal,
  IService,
  IServiceCenter,
  IAuto,
} from '../../config/interfaces';

import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: IGlobal = {
  autoId: null,
  selectServices: null,
  amount: null,
  dateAndTime: null,
  serviceCenter: null,
  visibleErrorModal: false,
  autoList: [],
  servicedCar: false,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setAutoId: (state, action: PayloadAction<number | null>): void => {
      state.autoId = action.payload;
    },
    setSelectServices: (state, action: PayloadAction<IService[] | null>): void => {
      state.selectServices = action.payload;
    },
    setAmount: (state, action: PayloadAction<number | null>): void => {
      state.amount = action.payload;
    },
    setDateAndTime: (state, action: PayloadAction<string | null>): void => {
      state.dateAndTime = action.payload;
    },
    setServiceCenter: (state, action: PayloadAction<IServiceCenter | null>): void => {
      state.serviceCenter = action.payload;
    },
    clearServiceCenter: (state) => {
      state.serviceCenter = null;
    },
    setVisibleErrorModal: (state, action: PayloadAction<boolean>): void => {
      state.visibleErrorModal = action.payload;
    },
    setAutoListStore: (state, action: PayloadAction<IAuto[]>): void => {
      state.autoList = action.payload;
    },
    setServicedCar: (state, action: PayloadAction<boolean>): void => {
      state.servicedCar = action.payload;
    },
  },
});

export const {
  setAutoId,
  setSelectServices,
  setAmount,
  setDateAndTime,
  setServiceCenter,
  clearServiceCenter,
  setVisibleErrorModal,
  setAutoListStore,
  setServicedCar,
} = globalSlice.actions;
