import React from 'react';

import { AddNewUser } from '../../features';

const NewUserPage: React.FC = () => {
  return (
    <AddNewUser />
  );
};

export default NewUserPage;
