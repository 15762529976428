/*TODO: Блок с социальными сетями скрыт SM-165 (Компонент готовый)  */
import React from 'react';

import classNames from 'classnames/bind';

import { Button } from '@mui/material';

// import iconWhatsapp from '../../../../assets/icons/icon_whatsapp.svg';
import iconTelegram from '../../../../assets/icons/icon_telegram.svg';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface ICardProps {
  address: string
  openHours: string
  location: number
  telegramLink?: string
  whatsappLink?: string
  onCallMe: () => void
}

const Card: React.FC<ICardProps> = ({
  address,
  openHours,
  location,
  telegramLink = '',
  whatsappLink = '',
  onCallMe,
}) => {
  return (
    <div className={cx('card')}>
      <div className={cx('card__info')}>
        <div className={cx('card__address')}>{address}</div>
        <div className={cx('card__schedule')}>Открыто {openHours}</div>
      </div>
      <div className={cx('card__locality')}>{location.toFixed(2)} км от вас</div>
      <div className={cx('card__communication')}>
        <ul className={cx('card__social')}>
          <li>
            <a href={telegramLink} target="_blank" rel="noreferrer">
              <img src={iconTelegram} alt="Telegram" />
              <span>Чат в Telegram</span>
            </a>
          </li>
          {/*<li>*/}
          {/*  <a href={whatsappLink} target="_blank" rel="noreferrer">*/}
          {/*    <img src={iconWhatsapp} alt="WhatsApp" />*/}
          {/*    <span>Чат в WhatsApp</span>*/}
          {/*  </a>*/}
          {/*</li>*/}
        </ul>
        <Button
          variant="contained"
          className={cx('button', 'button--small', 'button--callback')}
          onClick={() => onCallMe()}
        >
          Перезвоните мне
        </Button>
      </div>
    </div>
  );
};

export default Card;
