import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button } from '@mui/material';

import arrowLeft from '../../assets/icons/icon_arrow_left.svg';

import { IService } from '../../shared/config/interfaces';

import { useAppDispatch } from '../../shared/config/hooks';

import { setAmount, setSelectServices, setVisibleErrorModal } from '../../shared/store/slices/globalSlice';

import { ErrorModal, ServiceCard } from '../../shared/ui';

import { serviceApi, useGetServicesListQuery } from '../../app/api/serviceApi';

import { EServiceTypes } from '../../shared/config/enums';

import cheapOption from '../../shared/functions/cheapOption';

import { TOKEN_ERROR } from '../../shared/config/constants';
import { clearTokenData } from '../../shared/store/slices/authSlice';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const WashingPage: React.FC = () => {
  const [washing, setWashing] = useState<IService[]>([]);
  const [selectedWashingIds, setSelectedWashingIds] = useState<number[]>([]);
  const [selectedWashingOptions, setSelectedWashingOptions] = useState<{[serviceId: number]: number}>({});
  const [errorText, setErrorText] = useState<string>('');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleError = (error: unknown) => {
    if (error && typeof error === 'object' && 'data' in error) {
      const errorData = (error as { data: { errors?: { detail?: string }[] } }).data;
      if (Array.isArray(errorData.errors) && errorData.errors[0]?.detail) {
        setErrorText(errorData.errors[0].detail);
        dispatch(setVisibleErrorModal(true));
      }
    }
  };

  const { data, error } = useGetServicesListQuery(id);

  useEffect(() => {
    if (data) {
      setWashing(data.filter((item: IService) => item.service_type === EServiceTypes.WASHING));
    }
    if (error) {
      handleError(error);
    }
  }, [data, error]);

  const selectedWashing = washing.filter((service) => (selectedWashingIds.includes(service.id)));

  const getFilterSelectedWashing = (
    services: IService[],
    selections: { [key: number]: number },
  ): IService[] => {
    return services.map((service) => {
      if (selections[service.id] !== undefined) {
        const selectedOptionId = selections[service.id];
        return {
          ...service,
          options: service.options.filter((option) => option.id === selectedOptionId),
        };
      } else if (selections[service.id] === undefined) {
        return {
          ...service,
          options: [cheapOption(service.options)],
        };
      }
      return service;
    });
  };

  const filteredServices = getFilterSelectedWashing(selectedWashing, selectedWashingOptions);

  const getAmount = (arr: IService[]) => {
    return arr.reduce((total, item) => total + item.options[0].total_price, 0);
  };

  return (
    <div className={cx('container', 'full-height')}>
      <div className={cx('washing__container')}>
        <Link to={'/'} className={cx('link-goto')}>
          <img src={arrowLeft} alt="Назад в гараж" />
          Назад в гараж
        </Link>
        <div className={cx('heading')}>
          <h2>Выберите тип мойки</h2>
        </div>
        {washing.length !== 0 ? (
          <div className={cx('washing__list', 'with-fixed-buttons')}>
            {washing.map((service) => (
              <ServiceCard
                type={service.service_type}
                key={service.id}
                service={service}
                isSelected={selectedWashingIds.includes(service.id)}
                setSelectedServiceIds={setSelectedWashingIds}
                selectedServicesOptions={selectedWashingOptions}
                setSelectedServicesOptions={setSelectedWashingOptions}
              />
            ))}
          </div>
        ) : (
          <div className={cx('h3')}>На данный момент услуги мойки не доступны</div>
        )}
        <div className={cx('buttons-group', 'buttons-group--total', 'buttons-group--fixed')}>
          <div className={cx('total')}>
            <div className={cx('total__text')}>Итого</div>
            <div className={cx('total__value')}>
              {`${Number(getAmount(filteredServices).toFixed(2)).toLocaleString('ru-RU')} ₽`}
            </div>
          </div>
          <Button
            variant="contained"
            type="submit"
            className={cx('button')}
            onClick={() => {
              dispatch(setSelectServices(filteredServices));
              dispatch(setAmount(Number(getAmount(filteredServices).toFixed(2))));
              navigate('/select-service-center');
            }}
            disabled={!selectedWashingIds.length}
          >
            Добавить
          </Button>
        </div>
      </div>
      <ErrorModal
        description={errorText}
        btnTitle="Закрыть"
        btnAction={() => {
          if (errorText === TOKEN_ERROR) {
            dispatch(setVisibleErrorModal(false));
            dispatch(clearTokenData());
            dispatch(serviceApi.util.resetApiState());
            navigate('/auth');
          } else {
            dispatch(setVisibleErrorModal(false));
          }
        }}
      />
    </div>
  );
};

export default WashingPage;
