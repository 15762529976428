import React from 'react';

import logoSplashScreen from '../../../assets/images/logo_splashscreen.png';

const SplashScreen: React.FC = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100vh',
      background: '#222222',
    }}
    >
      <img src={logoSplashScreen} alt="splashscreen" />
    </div>
  );
};

export default SplashScreen;
