import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Button, TextField } from '@mui/material';

import arrowLeft from '../../assets/icons/icon_arrow_left.svg';

import { useAddLicensePlateMutation, useGetCurrentCarQuery } from '../../app/api/autoApi';

import { regexPlateNumber } from '../../shared/config/regexConfig';

import { IFormFields } from './config/interfaces';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface ISubmitForm {
  licensePlate: string
}

const AddLicensePlatePage: React.FC = () => {
  const [errorText, setErrorText] = useState<string | null>(null);

  const navigate = useNavigate();

  const { id } = useParams();

  const methods = useForm<IFormFields>({
    defaultValues:
      {
        licensePlate: '',
      },
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
  } = methods;

  const { data: carData } = useGetCurrentCarQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (carData && carData.license_plate) {
      reset({
        ...carData,
        licensePlate: carData.license_plate,
      });
    }
  }, [carData]);

  const [addLicensePlate, { isSuccess, error }] = useAddLicensePlateMutation({});

  useEffect(() => {
    if (isSuccess) {
      navigate('/');
    }
    if (error) {
      if (typeof error === 'object' && 'data' in error) {
        const errorData = error.data as { errors?: { detail?: string }[] };
        if (Array.isArray(errorData.errors) && errorData.errors[0]?.detail) {
          setErrorText(errorData.errors[0].detail);
        }
      }
    }
  }, [isSuccess, error]);

  const onSubmit: SubmitHandler<ISubmitForm> = (data) => {
    addLicensePlate({
      ...carData,
      license_plate: data.licensePlate,
      modification_id: carData?.modification?.id,
    });
  };

  return (
    <div className={cx('container', 'full-height')}>
      <div className={cx('add-license-plate__container')}>
        <form onSubmit={handleSubmit(onSubmit)} className={cx('form', 'full-height')}>
          <Link to={'/'} className={cx('link-goto')}>
            <img src={arrowLeft} alt="Назад в гараж" />
            Назад в гараж
          </Link>
          <div className={cx('heading')}>
            <h2>{`${carData?.modification?.make || ''} ${carData?.modification?.model || ''}`}</h2>
            <div className={cx('h3')}>Укажите гос номер автомобиля</div>
          </div>
          <div className={cx('form__row')}>
            <Controller
              name="licensePlate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Обязательное поле',
                },
                pattern: {
                  value: regexPlateNumber,
                  message: 'Некорректный гос номер',
                },
              }}
              render={({ field, fieldState: { error: errorField } }) => (
                <TextField
                  id="licensePlate"
                  label="Гос номер"
                  variant="outlined"
                  error={!!errorField}
                  helperText={errorField ? errorField.message : null}
                  {...field}
                  className={cx('form__input')}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const filteredValue = newValue.replace(/[^авекмнорстух0-9]/gi, '');
                    if (filteredValue !== newValue) {
                      field.onChange(filteredValue.toUpperCase());
                    } else {
                      field.onChange(newValue.toUpperCase());
                    }
                  }}
                />
              )}
            />
            {errorText && (
              <div className={cx('error')}>
                {errorText}
              </div>
            )}
          </div>
          <div className={cx('form__row', 'form__row--buttons')}>
            <Button
              variant="contained"
              type="submit"
              className={cx('button')}
              disabled={watch('licensePlate') === carData?.licensePlate}
            >
              Сохранить гос номер
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddLicensePlatePage;
