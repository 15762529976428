import React from 'react';
import classNames from 'classnames/bind';

import { EWorkTypes } from '../../config/enums';

import convertTime from '../../functions/convertTime';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface ISingleOptionProps {
  option: {
    id: number
    quality_level: EWorkTypes
    total_price: number
    spare_parts_total_price: number
    works: {
      quantity: number
      price:	number
      work:	{
        name: string
        unit_price: number
      }
    }[]
    spare_parts: {
      name: string
      price: number
      spare_part: {
        quantity: number
        name: string
        unit_price: string
        make: string
        part_number: string
      }
    }[]
  }
}

const SingleOption: React.FC<ISingleOptionProps> = ({
  option,
}) => {
  return (
    <div className={cx('option')}>
      <div className={cx('radiobutton-wrapper')}>
        <span className={cx('label-text')}>
          <span className={cx('option--title')}>{option.quality_level}</span>
          {option.spare_parts.length ? (
            <>
              {option.spare_parts.map((item) => {
                const { name, make, part_number } = item.spare_part;
                return (
                  <div className={cx('option--element')}>{`${name}, ${make}, ${part_number}`}</div>
                );
              })}
            </>
          ) : null}
          <span className={cx('option--price')}>
            {`${option.spare_parts_total_price.toLocaleString('ru-RU')} ₽`}
          </span>
        </span>
        {option.works.length && (
          <div className={cx('option--list')}>
            <span className={cx('option--title')}>Работы</span>
            {option.works.map((item) => {
              const { quantity, price, work } = item;
              return (
                <div className={cx('option--element__wrapper')}>
                  <div className={cx('option--element')}>
                    {`${work.name}, ${convertTime(quantity)}, `}
                  </div>
                  <div>{`${price.toLocaleString('ru-RU')} ₽`}</div>
                </div>
              );
            })}
          </div>
        )}
        <div />
      </div>
    </div>
  );
};

export default SingleOption;
