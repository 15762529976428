// eslint-disable-next-line no-shadow
export const enum EWorkTypes {
  PREMIUM = 'PREMIUM',
  GOLD = 'GOLD',
  SILVER = 'SILVER',
}

// eslint-disable-next-line no-shadow
export const enum EServiceTypes {
  MAINTENANCE = 'MAINTENANCE',
  WASHING = 'WASHING',
  SERVICE_WORK = 'SERVICE_WORK',
  DIAGNOSTIC = 'DIAGNOSTIC',
}

// eslint-disable-next-line no-shadow
export const enum ECallbackTypes {
  SERVICE_CENTER = 'SERVICE_CENTER',
  KASKO = 'KASKO',
  OSAGO = 'OSAGO',
  'TRADE-IN' = 'TRADE-IN',
}

// eslint-disable-next-line no-shadow
export const enum EFieldTypes {
  PLATE_NUMBER = 'plateNumber',
  BRAND = 'brand',
  MODEL = 'model',
  YEAR = 'year',
  MODIFICATION = 'modification',
}
