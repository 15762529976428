import React from 'react';
import classNames from 'classnames/bind';

import { useAppSelector } from '../../shared/config/hooks';

import { SliderComponent } from './ui';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const DoneApplicationPage: React.FC = () => {
  const servicedCar = useAppSelector((state) => state.global.servicedCar);

  return (
    <div className={cx('container', 'full-height')}>
      <div className={cx('application__container')}>
        {
          servicedCar ? (
            <div className={cx('heading')}>
              <h2>Ваша заявка успешно отправлена!</h2>
              <div className={cx('h3')}>Мы свяжемся с&nbsp;вами в&nbsp;ближайшее время</div>
            </div>
          ) : (
            <div className={cx('heading')}>
              <h2>Ваша заявка успешно отправлена!</h2>
              <div className={cx('h3')}>
                К&nbsp;сожалению, в&nbsp;данный момент мы
                 не&nbsp;обслуживаем авто вашей марки, но уже работаем над&nbsp;этим.
              </div>
              <div className={cx('h3')}>
                Обязательно свяжемся с&nbsp;вами, когда обслуживание будет доступно.
              </div>
            </div>
          )
        }
        <div className={cx('slider__wrapper')}>
          <h3>Рекомендуем</h3>
          <SliderComponent />
        </div>
      </div>
    </div>
  );
};

export default DoneApplicationPage;
