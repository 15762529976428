import { EWorkTypes } from '../config/enums';
import { IServiceOption } from '../config/interfaces';

const sortOptions = (arr: IServiceOption[]): IServiceOption[] => {
  const order = [EWorkTypes.PREMIUM, EWorkTypes.GOLD, EWorkTypes.SILVER];

  return [...arr].sort((a, b) => order.indexOf(a.quality_level)
      - order.indexOf(b.quality_level));
};

export default sortOptions;
