import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, TextField } from '@mui/material';

import { useAddUserNameMutation } from '../../app/api/userApi';
import { setUserName } from '../../shared/store/slices/authSlice';
import { useAppDispatch } from '../../shared/config/hooks';

import { IFormFields } from './config/interfaces';
import styles from './styles.module.scss';

interface ISubmitForm {
  firstName: string
}

const cx = classNames.bind(styles);

const AddNewUser: React.FC = () => {
  const [errorText, setErrorText] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const methods = useForm<IFormFields>({
    defaultValues:
      {
        firstName: '',
      },
  });

  const {
    control,
    handleSubmit,
    watch,
  } = methods;

  const [addUserName, { data: userData, isSuccess, error }] = useAddUserNameMutation({});

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUserName(userData.first_name));
      navigate('/');
    }
    if (error) {
      if (typeof error === 'object' && 'data' in error) {
        const errorData = error.data as { errors?: { detail?: string }[] };
        if (Array.isArray(errorData.errors) && errorData.errors[0]?.detail) {
          setErrorText(errorData.errors[0].detail);
        }
      }
    }
  }, [isSuccess, error]);

  const onSubmit: SubmitHandler<ISubmitForm> = (data) => {
    addUserName({
      first_name: data.firstName,
    });
  };

  return (
    <div className={cx('container', 'full-height')}>
      <div className={cx('add-user__container')}>
        <form onSubmit={handleSubmit(onSubmit)} className={cx('form', 'full-height')}>
          <div className={cx('heading')}>
            <h2>Как к вам обращаться</h2>
          </div>
          <div className={cx('form__row')}>
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Обязательное поле',
                },
              }}
              render={({ field, fieldState: { error: errorField } }) => (
                <TextField
                  id="firstName"
                  label="Ваше имя"
                  variant="outlined"
                  error={!!errorField}
                  helperText={errorField ? errorField.message : null}
                  {...field}
                  className={cx('form__input')}
                />
              )}
            />
            {errorText && (
              <div className={cx('error')}>
                {errorText}
              </div>
            )}
          </div>
          <div className={cx('form__row', 'form__row--buttons')}>
            <Button
              variant="contained"
              type="submit"
              className={cx('button')}
              disabled={watch('firstName') === ''}
            >
              Сохранить
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewUser;
