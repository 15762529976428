import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_API_URL;

interface RootState {
  auth: {
    token: string;
  };
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;
      headers.set('Content-Type', 'application/json');
      headers.set('Authorization', `Token ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUserName: builder.query<any, void>({
      query: () => '/api/account/profile/',
    }),
    addUserName: builder.mutation<any, any>({
      query: (arg) => ({
        url: '/api/account/profile/',
        method: 'PUT',
        body: arg,
      }),
    }),
    createCallback: builder.mutation<any, any>({
      query: (arg) => ({
        url: '/api/callback/',
        method: 'POST',
        body: arg,
      }),
    }),
  }),
});

export const {
  useGetUserNameQuery,
  useAddUserNameMutation,
  useCreateCallbackMutation,
} = userApi;
