import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { IService, IServiceOption } from '../../config/interfaces';

import iconChecked from '../../../assets/icons/icon_checked.svg';
import iconPlusWhite from '../../../assets/icons/icon_plus_white.svg';

import { OptionsItem, SingleOption } from '../index';

import { EServiceTypes } from '../../config/enums';

import sortOptions from '../../functions/sortOptions';
import convertTime from '../../functions/convertTime';
import cheapOption from '../../functions/cheapOption';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface ISelectedOptions {
  [serviceId: number]: number;
}

interface IServiceCardProps {
  service: IService
  isSelected: boolean
  setSelectedServiceIds: React.Dispatch<React.SetStateAction<number[]>>
  selectedServicesOptions: {[serviceId: number]: number}
  setSelectedServicesOptions: (arg: ISelectedOptions) => void
  type: EServiceTypes
}

const ServiceCard: React.FC<IServiceCardProps> = ({
  service,
  isSelected,
  setSelectedServiceIds,
  selectedServicesOptions,
  setSelectedServicesOptions,
  type,
}) => {
  const {
    id,
    name,
    options,
  } = service;

  const [sortedOptions, setSortedOptions] = useState<IServiceOption[]>([]);
  const [isVisibleType, setIsVisibleType] = useState<boolean>(false);
  const [selectedOptionId, setSelectedOptionId] = useState<number | null>(null);
  const [selectedOption, setSelectedOption] = useState<IServiceOption | undefined>(undefined);

  const handleOptionChange = (serviceId: number, optionId: number) => {
    setSelectedOptionId(optionId);
    setSelectedServicesOptions({
      ...selectedServicesOptions,
      [serviceId]: optionId,
    });
  };

  useEffect(() => {
    setSortedOptions(sortOptions(options));
  }, [options]);

  useEffect(() => {
    if (sortedOptions.length > 0) {
      setSelectedOptionId(cheapOption(sortedOptions).id);
    }
  }, [sortedOptions]);

  useEffect(() => {
    if (selectedOptionId !== null) {
      setSelectedOption(sortedOptions.find((item) => item.id === selectedOptionId));
    }
  }, [selectedOptionId]);

  const handleClick = (serviceId: number) => {
    setSelectedServiceIds((prev: number[]) => {
      if (prev.includes(serviceId)) {
        return prev.filter((item) => item !== serviceId);
      } else {
        return [...prev, serviceId];
      }
    });
  };

  const selectServiceClick = (serviceId: number) => {
    setSelectedServiceIds((prev: number[]) => {
      if (prev.includes(serviceId)) {
        return prev;
      } else {
        return [...prev, serviceId];
      }
    });
  };

  return (
    <div className={cx('services__item', { selected: isSelected })}>
      <div
        className={cx('services__item--header')}
        onClick={() => {
          setIsVisibleType(!isVisibleType);
        }}
        role="button"
        tabIndex={0}
      >
        <div className={cx('services__item--name')}>{name}</div>
        <button
          className={cx('button-expand', { 'button-expand--open': isVisibleType })}
        />
        <div
          className={cx('services__item--description')}
        >
          {selectedOption?.quality_level}
        </div>
      </div>
      {isVisibleType && (
        sortedOptions.length === 1 ? (
          sortedOptions.map((option) => (
            <SingleOption
              key={option.id}
              option={option}
            />
          ))
        ) : (
          <>
            {sortedOptions.map((option) => (
              <OptionsItem
                type={type}
                key={option.id}
                option={option}
                serviceId={id}
                selectedOptionId={selectedOptionId}
                onOptionChange={handleOptionChange}
                selectService={() => { selectServiceClick(id); }}
              />
            ))}
            {selectedOption && selectedOption.works.length && type !== EServiceTypes.WASHING && (
              <div className={cx('option--list')}>
                <span className={cx('option--title')}>Работы</span>
                {selectedOption.works.map((item) => {
                  const { quantity, price, work } = item;
                  return (
                    <div className={cx('option--element__wrapper')}>
                      <div className={cx('option--element')}>
                        {`${work.name}, ${convertTime(quantity)}, `}
                      </div>
                      <div>{`${price.toLocaleString('ru-RU')} ₽`}</div>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )
      )}
      <div className={cx('services__item--price-wrapper')}>
        <div className={cx('services__item--price')}>
          {`${selectedOption?.total_price.toLocaleString('ru-RU')} ₽`}
        </div>
        {sortedOptions.length !== 1 && (
          <div className={cx('services__item--price-options')}>Доступны варианты</div>
        )}
      </div>
      <div className={cx('cars__item--buttons')}>
        {isSelected ? (
          <button
            className={cx('button--xs', 'button--xs-selected')}
            onClick={() => handleClick(id)}
          >
            Выбрано
            <img src={iconChecked} alt="" />
          </button>
        ) : (
          <button
            className={cx('button--xs')}
            onClick={() => handleClick(id)}
          >
            Выбрать
            <img src={iconPlusWhite} alt="" />
          </button>
        )}
      </div>
    </div>
  );
};

export default ServiceCard;
