import React from 'react';
import classNames from 'classnames/bind';

import { Button } from '@mui/material';

import iconClose from '../../../../assets/icons/icon_close.svg';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface IMobileLayoutProps {
  children: React.ReactNode
  onClose: () => void
  mainBtnTitle: string
  mainBtnAction: () => void
}

const MobileLayout: React.FC<IMobileLayoutProps> = ({
  children,
  onClose,
  mainBtnTitle,
  mainBtnAction,
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={cx('background')}
      onClick={onClose}
    >
      <div className={cx('modal')}>
        <button
          className={cx('button-close')}
          onClick={onClose}
        >
          <img src={iconClose} alt="Закрыть" />
        </button>
        <div className={cx('container')}>
          {children}
        </div>
        <div className={cx('buttons-group', 'buttons-group--list')}>
          <Button
            className={cx('button', 'list__button-add')}
            variant="contained"
            onClick={mainBtnAction}
          >
            {mainBtnTitle}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MobileLayout;
