import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_API_URL;

interface RootState {
  auth: {
    token: string;
  };
}

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;
      headers.set('Content-Type', 'application/json');
      headers.set('Authorization', `Token ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createOrder: builder.mutation<any, any>({
      query: (arg) => ({
        url: '/api/orders/',
        method: 'POST',
        body: arg,
      }),
    }),
  }),
});

export const {
  useCreateOrderMutation,
} = orderApi;
