import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import classNames from 'classnames/bind';

import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from '../../shared/config/hooks';

import arrowLeft from '../../assets/icons/icon_arrow_left.svg';

import { orderApi, useCreateOrderMutation } from '../../app/api/orderApi';

import { ErrorModal } from '../../shared/ui';

import { setVisibleErrorModal } from '../../shared/store/slices/globalSlice';

import { TOKEN_ERROR } from '../../shared/config/constants';
import { clearTokenData } from '../../shared/store/slices/authSlice';

import { ServiceItem } from './ui';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const ConfirmPage: React.FC = () => {
  const [errorText, setErrorText] = useState<string>('');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const autoId = useAppSelector((state) => state.global.autoId);
  const selectServices = useAppSelector((state) => state.global.selectServices);
  const amount = useAppSelector((state) => state.global.amount);
  const dateAndTime = useAppSelector((state) => state.global.dateAndTime);
  const serviceCenter = useAppSelector((state) => state.global.serviceCenter);
  const autoList = useAppSelector((state) => state.global.autoList);

  const currentAuto = autoList.find((auto) => auto.id === autoId);
  const currentDate = dayjs(dayjs(dateAndTime).toDate()).format('DD.MM.YYYY, HH:mm');
  const {
    id: serviceCenterId,
    region,
    settlement,
    street,
    name,
    house,
  } = serviceCenter || {};

  const selectedOptionsIds: number[] | undefined = selectServices?.flatMap(
    (service) => service.options.map((option) => option.id),
  );

  const handleError = (error: unknown) => {
    if (error && typeof error === 'object' && 'data' in error) {
      const errorData = (error as { data: { errors?: { detail?: string }[] } }).data;
      if (Array.isArray(errorData.errors) && errorData.errors[0]?.detail) {
        setErrorText(errorData.errors[0].detail);
        dispatch(setVisibleErrorModal(true));
      }
    }
  };

  const [createOrder, { isSuccess, isLoading, error }] = useCreateOrderMutation({});

  useEffect(() => {
    if (isSuccess) {
      navigate('/done');
    }
    if (error) {
      handleError(error);
    }
  }, [isSuccess, error]);

  return (
    <div className={cx('container', 'full-height')}>
      <div className={cx('confirm__container')}>
        <Link to={'/date-and-time-visit'} className={cx('link-goto')}>
          <img src={arrowLeft} alt="Назад к выбору даты и времени" />
          Назад к выбору даты и времени
        </Link>
        <div className={cx('heading')}>
          <h2>Подтверждение записи</h2>
        </div>
        <div className={cx('confirm-block')}>
          <h3>Авто</h3>
          {currentAuto && (
            <div
              className={cx('confirm-block__value')}
            >
              {`${currentAuto?.modification?.make} ${currentAuto?.modification?.model}`}
            </div>
          )}
        </div>
        {selectServices && (
          <div className={cx('confirm-block')}>
            <h3>Услуги</h3>
            <div className={cx('services__list')}>
              {selectServices.map((service) => (
                <ServiceItem
                  key={service.id}
                  service={service}
                  type={service.service_type}
                />
              ))}
            </div>
          </div>
        )}
        {amount && (
          <div className={cx('confirm-block')}>
            <h3>Обшая сумма</h3>
            <div className={cx('confirm-block__value')}>{`${amount.toLocaleString('ru-RU')} ₽`}</div>
          </div>
        )}
        <div className={cx('confirm-block')}>
          <h3>Сервис</h3>
          <div className={cx('confirm-block__value')}>
            {`${name}, ${region}, ${settlement}, ${street}, ${house}`}
          </div>
        </div>
        <div className={cx('confirm-block')}>
          <h3>Дата и время</h3>
          <div className={cx('confirm-block__value')}>
            {currentDate}
          </div>
        </div>
        <div className={cx('buttons-group')}>
          <Button
            variant="contained"
            type="submit"
            className={cx('button', 'button--confirm')}
            disabled={isLoading}
            onClick={() => {
              createOrder({
                service_datetime: dateAndTime,
                car_id: autoId,
                service_center_id: serviceCenterId,
                service_options_id: selectedOptionsIds,
              });
            }}
          >
            Подтвердить запись
          </Button>
        </div>
      </div>
      <ErrorModal
        description={errorText}
        btnTitle="Закрыть"
        btnAction={() => {
          if (errorText === TOKEN_ERROR) {
            dispatch(setVisibleErrorModal(false));
            dispatch(clearTokenData());
            dispatch(orderApi.util.resetApiState());
            navigate('/auth');
          } else {
            dispatch(setVisibleErrorModal(false));
          }
        }}
      />
    </div>
  );
};

export default ConfirmPage;
