import { ECallbackTypes } from '../../../shared/config/enums';

import { ISlider } from './interfaces';

export const SLIDER: ISlider[] = [
  {
    id: 0,
    name: 'ПОЛИС КАСКО',
    discount: 'Сэкономьте до 40%',
    description: 'Начиная с первого страхового случая часть расходов на ремонт'
    + 'автомобиля оплачиваете вы, остальное покрывает страховая компания.'
    + 'Так вы сможете сэкономить',
    btnText: 'Оформить КАСКО',
    type: ECallbackTypes.KASKO,
  },
  {
    id: 1,
    name: 'ПОЛИС ОСАГО',
    discount: 'Сэкономьте до 20%',
    description: 'Начиная с первого страхового случая страховая компания'
      + 'покрывает ремонт поврежденного автомобиля до 400 000 рублей. '
      + 'Предложим вам лучшие условия, если оставите заявку сейчас',
    btnText: 'Оформить ОСАГО',
    type: ECallbackTypes.OSAGO,
  },
  {
    id: 2,
    name: 'TRADE-IN АВТО',
    discount: 'Сэкономьте до 30%',
    description: 'Сдайте ваше авто в Trade-IN и получите скидку на покупку'
      + 'автомобиля в наших салонах. Предложим вам лучшие условия,'
      + 'если оставите заявку сейчас.',
    btnText: 'Оформить Trade-In',
    type: ECallbackTypes['TRADE-IN'],
  },
];
