/*TODO: Блок с социальными сетями скрыт SM-139 (Компонент готовый)  */

import React from 'react';
import classNames from 'classnames/bind';

import logo from '../../assets/images/logo_contacts.png';
// import iconTelegram from '../../assets/icons/icon_telegram.svg';
// import iconVK from '../../assets/icons/icon_vk.svg';
// import iconOdnoklassniki from '../../assets/icons/icon_odnoklassniki.svg';
// import iconYoutube from '../../assets/icons/icon_youtube.svg';
import iconNext from '../../assets/icons/icon_next.svg';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const ContactsPage: React.FC = () => {
  return (
    <div className={cx('container', 'full-height')}>
      <div className={cx('contacts__container')}>
        <div className={cx('heading')}>
          <h2>Контакты</h2>
        </div>
        <div className={cx('contacts__logo')}>
          <img src={logo} alt="Service me" className={cx('logo')} />
          <div className={cx('copyright')}>Service Me &copy;</div>
        </div>
        <div className={cx('contacts__address')}>
          <h3>Юридический адрес</h3>
          <div className={cx('adr')}>
            350900, Краснодарский край, г.о. город Краснодар, г. Краснодар, ул. им. Дзержинского д. 229/5, помещен. ½
          </div>
        </div>
        <div className={cx('contacts__email')}>
          <h3>E-Mail для связи</h3>
          <div className={cx('email')}>
            <a href="mailto:contact@serviceme.pro">contact@serviceme.pro</a>
          </div>
        </div>
        <div className={cx('contacts__list')}>
          {/*<h3>Наши соцсети</h3>*/}
          {/*<ul className={cx('list')}>*/}
          {/*  <li>*/}
          {/*    <a href="" target="_blank">*/}
          {/*      <img src={iconTelegram} alt="Telegram" />*/}
          {/*      Канал в Telegram*/}
          {/*      <img src={iconNext} alt="" className={cx('follow')} />*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <a href="" target="_blank">*/}
          {/*      <img src={iconVK} alt="ВКонтакте" />*/}
          {/*      Сообщество в ВКонтакте*/}
          {/*      <img src={iconNext} alt="" className={cx('follow')} />*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <a href="" target="_blank">*/}
          {/*      <img src={iconOdnoklassniki} alt="Одноклассники" />*/}
          {/*      Сообщество в Одноклассниках*/}
          {/*      <img src={iconNext} alt="" className={cx('follow')} />*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <a href="" target="_blank">*/}
          {/*      <img src={iconYoutube} alt="Youtube" />*/}
          {/*      Наш Youtube канал*/}
          {/*      <img src={iconNext} alt="" className={cx('follow')} />*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*</ul>*/}
        </div>
        <div className={cx('contacts__list')}>
          <h3>Нормативные документы</h3>
          <ul className={cx('list')}>
            <li>
              <a href="/documents/license.pdf" target="_blank" rel="noreferrer">
                Согласие на получение рекламной рассылки
                <img src={iconNext} alt="" className={cx('follow')} />
              </a>
            </li>
            <li>
              <a href="/documents/personal-data.pdf" target="_blank" rel="noreferrer">
                Политика конфиденциальности
                <img src={iconNext} alt="" className={cx('follow')} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ContactsPage;
