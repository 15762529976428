// Функция для расчета расстояния между двумя точками в километрах
const calculateDistance = (coordA: number[], coordB: number[]): number => {
  const toRad = (value: number) => (value * Math.PI) / 180;
  const [lon1, lat1] = coordA;
  const [lon2, lat2] = coordB;

  const R = 6371; // Радиус Земли в км
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2))
    * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

export default calculateDistance;
