/* TODO: Создан компонент страницы лендинга SM-185. (Дубль из репозитория лендинга) */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import logo from '../../assets/images/landing/logo.svg';
import price from '../../assets/images/landing/price.png';
import quality from '../../assets/images/landing/quality.png';
import speed from '../../assets/images/landing/speed.png';

import styles from './styles.module.css';

const cx = classNames.bind(styles);

const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={cx('main-wrapper')}>
      <section className={cx('main')}>
        <div className={cx('container')}>
          <div className={cx('header')}>
            <img src={logo} alt="Service Me" />
          </div>
          <div className={cx('h1')}>Service me</div>
          <span>Качественный сервис рядом с домом</span>
          <div className={cx('buttons')}>
            <button
              className={cx('btn', 'black')}
              onClick={() => navigate('/')}
            >
                Запись на мойку
            </button>
            <button
              className={cx('btn', 'white')}
              onClick={() => navigate('/')}
            >
                Запись на сервис
            </button>
          </div>
        </div>
      </section>
      <section className={cx('about')}>
        <div className={cx('h2')}>О нас</div>
        <div className={cx('feature')}>
          <img src={quality} alt="Качество рядом" />
          <div className={cx('text')}>
            <div className={cx('h3')}>Качество рядом</div>
            <span>Больше не нужно ехать десятки километров за качественным сервисом</span>
          </div>
        </div>
        <div className={cx('feature')}>
          <img src={price} alt="Ваша цена" />
          <div className={cx('text')}>
            <div className={cx('h3')}>Ваша цена</div>
            <span>Выбирайте лучший для вас вариант из трех типов запчастей</span>
          </div>
        </div>
        <div className={cx('feature')}>
          <img src={speed} alt="Скорость и гарантия" />
          <div className={cx('text')}>
            <div className={cx('h3')}>Скорость и гарантия</div>
            <span>Не придется ждать авто из сервиса месяцами. Гарантируем высокое качество произведенных работ</span>
          </div>
        </div>
      </section>
      <section className={cx('cta')}>
        <div className={cx('h2')}>Обслуживайте авто вместе с нами</div>
        <div className={cx('buttons')}>
          <button
            className={cx('btn', 'white')}
            onClick={() => navigate('/')}
          >
              Запись на сервис
          </button>
          <button
            className={cx('btn', 'black')}
            onClick={() => navigate('/')}
          >
              Запись на мойку
          </button>
        </div>
      </section>
      <footer>
        <span>&copy; Service Me</span>
        <span>2024</span>
      </footer>
    </div>
  );
};

export default LandingPage;
