import React from 'react';
import classNames from 'classnames/bind';

import { EServiceTypes, EWorkTypes } from '../../config/enums';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface IOptionsItemProps {
  serviceId: number
  option: {
    id: number
    quality_level: EWorkTypes
    total_price: number
    works_total_price: number
    spare_parts_total_price: number
    works: {
      quantity: number
      price:	number
      work:	{
        name: string
        unit_price: number
      }
    }[]
    spare_parts: {
      name: string
      price: number
      spare_part: {
        quantity: number
        name: string
        unit_price: string
        make: string,
        part_number: string
        provider: string
      }
    }[]
  }
  selectedOptionId: number | null
  onOptionChange: (serviceId: number, optionId: number) => void
  selectService: () => void
  type: EServiceTypes
}

const OptionsItem: React.FC<IOptionsItemProps> = ({
  serviceId,
  option,
  selectedOptionId,
  onOptionChange,
  selectService,
  type = EServiceTypes.SERVICE_WORK,
}) => {
  return (
    <div className={cx('option')}>
      <div className={cx('radiobutton-wrapper')}>
        <input
          type="radio"
          value={option.id}
          name={`service-${serviceId}`}
          id={`option-${option.id}`}
          onChange={(e) => {
            onOptionChange(serviceId, Number(e.target.value));
            selectService();
          }}
          checked={selectedOptionId === option.id}
        />
        <label htmlFor={`option-${option.id}`}>
          <span className={cx('label-text')}>
            <span className={cx('option--title')}>{option.quality_level}</span>
            {type === EServiceTypes.WASHING && option.works.length && (
              <div className={cx('option--list_washing')}>
                {option.works.map((item) => (
                  <div className={cx('option--element')}>{item.work.name}</div>
                ))}
              </div>
            )}
            {option.spare_parts.length ? (
              <>
                {option.spare_parts.map((item) => {
                  const { name, make, part_number } = item.spare_part;
                  return (
                    <div className={cx('option--element')}>{`${name}, ${make}, ${part_number}`}</div>
                  );
                })}
              </>
            ) : null}
            <span className={cx('option--price')}>{
              type === EServiceTypes.WASHING ? (`${option.works_total_price.toLocaleString('ru-RU')} ₽`)
                : (`${option.spare_parts_total_price.toLocaleString('ru-RU')} ₽`)
            }
            </span>
          </span>
        </label>
      </div>
    </div>
  );
};

export default OptionsItem;

