import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button, TextField } from '@mui/material';

import ConfirmAuto from '../../components/ConfirmAuto';

import arrowLeft from '../../assets/icons/icon_arrow_left.svg';

import { AddNewAutoManual } from '../../features';

import { regexPlateNumber, regexVinNumber } from '../../shared/config/regexConfig';

import { autoApi, useAddCarMutation, useSearchAutoMutation } from '../../app/api/autoApi';

import { ErrorModal } from '../../shared/ui';

import { setVisibleErrorModal } from '../../shared/store/slices/globalSlice';

import { useAppDispatch } from '../../shared/config/hooks';

import { TOKEN_ERROR } from '../../shared/config/constants';
import { clearTokenData } from '../../shared/store/slices/authSlice';

import styles from './styles.module.scss';
import { EAddAutoTypes } from './config/enums';

const cx = classNames.bind(styles);

interface IFoundedCar {
  carTitle: string
  plateNumber: string
  generation: string
  modification: string
  year: number | string
}

const AddAutoPage: React.FC = () => {
  const [errorText, setErrorText] = useState<string>('');
  const [addAutoType, setAddAutoType] = useState<EAddAutoTypes>(EAddAutoTypes.vinNumber);
  const [foundCar, setFoundCar] = useState<IFoundedCar| null>(null);
  const [showModalConfirmAuto, setShowModalConfirmAuto] = useState<Boolean>(false);
  const [isSubmitAttempted, setIsSubmitAttempted] = useState<Boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleError = (error: unknown) => {
    if (error && typeof error === 'object' && 'data' in error) {
      const errorData = (error as { data: { errors?: { detail?: string }[] } }).data;
      if (Array.isArray(errorData.errors) && errorData.errors[0]?.detail) {
        setErrorText(errorData.errors[0].detail);
        dispatch(setVisibleErrorModal(true));
      }
    }
  };

  const [searchAuto, { data: carData, isSuccess, error: carError }] = useSearchAutoMutation({});

  useEffect(() => {
    if (isSuccess && carData) {
      setFoundCar({
        carTitle: `${carData?.car?.modification?.make} ${carData?.car?.modification?.model}`,
        plateNumber: carData?.car?.license_plate || 'Номер не указан',
        generation: carData?.car?.modification?.generation || '',
        modification: carData?.car?.modification?.modification || '',
        year: carData?.car?.year || '',
      });
      setShowModalConfirmAuto(true);
    }
    if (carError) {
      handleError(carError);
    }
  }, [isSuccess, carData, carError]);

  const [addCar, { isSuccess: isAddSuccess, error: addCarError }] = useAddCarMutation({});

  useEffect(() => {
    if (isAddSuccess) {
      navigate('/');
    }
    if (addCarError) {
      handleError(addCarError);
    }
  }, [isAddSuccess, addCarError]);

  const methods = useForm({
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    formState,
    watch,
    reset,
  } = methods;

  const plateNumber = watch('plateNumber');
  const vinNumber = watch('vinNumber');

  const closeShowModalConfirmAuto = () => {
    setShowModalConfirmAuto(false);
  };

  const getTitle = () => {
    return foundCar && showModalConfirmAuto ? 'Подтвердите авто' : 'Добавление авто';
  };

  const getDescription = () => {
    if (foundCar && showModalConfirmAuto) {
      return `Мы распознали авто по указанному 
        ${addAutoType === EAddAutoTypes.numberPlate ? 'Госномеру' : 'VIN номеру'}.
        Это ваше авто?`;
    } else {
      switch (addAutoType) {
      case EAddAutoTypes.numberPlate:
        return 'Укажите Госномер вашего авто для добавления в гараж';
      case EAddAutoTypes.vinNumber:
        return 'Укажите VIN номер вашего авто для добавления в гараж';
      case EAddAutoTypes.manual:
        return 'Не удалось определить ваш авто. Пожалуйста уточните информацию';
      default:
        return '';
      }
    }
  };

  const handleChangeType = () => {
    if (isSubmitAttempted) {
      setAddAutoType(EAddAutoTypes.manual);
    } else {
      addAutoType === EAddAutoTypes.numberPlate
        ? setAddAutoType(EAddAutoTypes.vinNumber)
        : setAddAutoType(EAddAutoTypes.numberPlate);
    }
  };

  const isButtonDisabled = () => {
    if (addAutoType === EAddAutoTypes.numberPlate) {
      return !plateNumber || !formState.isValid;
    }
    if (addAutoType === EAddAutoTypes.vinNumber) {
      return !vinNumber || !formState.isValid;
    }
    return false;
  };

  const getButtonDescription = () => {
    if (isSubmitAttempted) {
      return 'Указать данные вручную';
    } else {
      return `Добавить по ${addAutoType === EAddAutoTypes.numberPlate ? 'VIN' : 'Госномеру'}`;
    }
  };

  const onSaveAttempt = () => {
    setShowModalConfirmAuto(false);

    if (isSubmitAttempted) {
      setAddAutoType(EAddAutoTypes.manual);
    } else {
      setIsSubmitAttempted(true);
      addAutoType === EAddAutoTypes.numberPlate
        ? setAddAutoType(EAddAutoTypes.vinNumber)
        : setAddAutoType(EAddAutoTypes.numberPlate);
    }
  };

  const onSubmit = (data: {
    vinNumber?: string
    plateNumber?: string
  }) => {
    if (addAutoType === EAddAutoTypes.numberPlate) {
      searchAuto({
        license_plate: data.plateNumber,
      });
    }
    if (addAutoType === EAddAutoTypes.vinNumber) {
      searchAuto({
        vin: data.vinNumber,
      });
    }
  };

  const onSaveCar = () => {
    if (carData) {
      addCar({
        modification_id: carData?.car?.modification?.id,
        ...carData?.car,
      });
    }
  };

  return (
    <div className={cx('container', 'full-height')}>
      <div className={cx('add-auto__container')}>
        {foundCar && showModalConfirmAuto && (
          <Button
            type="button"
            variant="contained"
            onClick={() => closeShowModalConfirmAuto()}
            className={cx('button-text', 'height-auto')}
          >
            <img src={arrowLeft} alt="Назад к вводу номера" />
            <span>Назад к&nbsp;вводу </span>
            {addAutoType === EAddAutoTypes.numberPlate ? 'Госномера' : 'VIN номера'}
          </Button>
        )}
        <div className={cx('heading')}>
          <h2>{getTitle()}</h2>
          <div className={cx('h3')}>{getDescription()}</div>
        </div>
        {!showModalConfirmAuto && (addAutoType === EAddAutoTypes.numberPlate
        || addAutoType === EAddAutoTypes.vinNumber) && (
          <form onSubmit={handleSubmit(onSubmit)} className={cx('form', 'full-height')}>
            {addAutoType === EAddAutoTypes.numberPlate && (
              <div className={cx('form__row')}>
                <Controller
                  name={'plateNumber'}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Введите гос номер авто',
                    },
                    pattern: {
                      value: regexPlateNumber,
                      message: 'Некорректный гос номер',
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      value={field.value || ''}
                      label="Госномер"
                      variant="outlined"
                      placeholder="X777XX77"
                      autoComplete="off"
                      error={!!error}
                      helperText={error ? error.message : null}
                      className={cx('form__input')}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        // eslint-disable-next-line max-len
                        const filteredValue = newValue.replace(/[^авекмнорстухАВЕКМНОРСТУХ0-9]/g, '');
                        if (filteredValue !== newValue) {
                          field.onChange(filteredValue.toUpperCase());
                        } else {
                          field.onChange(newValue.toUpperCase());
                        }
                      }}
                    />
                  )}
                />
              </div>
            )}
            {addAutoType === EAddAutoTypes.vinNumber && (
              <div className={cx('form__row')}>
                <Controller
                  name={'vinNumber'}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Введите VIN номер авто',
                    },
                    pattern: {
                      value: regexVinNumber,
                      message: 'Некорректный VIN номер',
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      value={field.value || ''}
                      label="VIN Номер"
                      variant="outlined"
                      autoComplete="off"
                      error={!!error}
                      helperText={error ? error.message : 'VIN номер авто содержит 17 знаков'}
                      className={cx('form__input')}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const filteredValue = newValue.replace(/[^A-HJ-NPR-Z0-9]/gi, '');
                        if (filteredValue !== newValue) {
                          field.onChange(filteredValue.toUpperCase());
                        } else {
                          field.onChange(newValue.toUpperCase());
                        }
                      }}
                      inputProps={{ maxLength: '17' }}
                    />
                  )}
                />
              </div>
            )}
            <div className={cx('form__row', 'only-mobile')}>
              <Button
                type="button"
                variant="contained"
                className={cx('button', 'button-text', 'button--add', 'w100')}
                onClick={() => setAddAutoType(EAddAutoTypes.manual)}
              >
                Добавить вручную
              </Button>
            </div>
            <div className={cx('form__row', 'form__row--buttons')}>
              <Button
                type="submit"
                variant="contained"
                className={cx('button', 'button--add')}
                disabled={isButtonDisabled()}
              >
                Добавить
              </Button>
              <Button
                type="button"
                variant="contained"
                className={cx('button', 'button-border', 'button--add')}
                onClick={() => {
                  handleChangeType();
                  reset({});
                }}
              >
                {getButtonDescription()}
              </Button>
              <Button
                type="button"
                variant="contained"
                className={cx('button', 'button-text', 'button--add', 'w100', 'only-desktop')}
                onClick={() => setAddAutoType(EAddAutoTypes.manual)}
              >
                Добавить вручную
              </Button>
            </div>
          </form>
        )}
        {addAutoType === EAddAutoTypes.manual && (
          <AddNewAutoManual />
        )}
        {foundCar && showModalConfirmAuto && (
          <ConfirmAuto
            onSaveAttempt={() => onSaveAttempt()}
            onSaveCar={() => onSaveCar()}
            car={{
              type: addAutoType === EAddAutoTypes.numberPlate ? 'gosnumber' : 'vin',
              title: foundCar.carTitle,
              plateNumber: foundCar.plateNumber,
              generation: foundCar.generation,
              modification: foundCar.modification,
              year: foundCar.year,
            }}
          />
        )}
      </div>
      <ErrorModal
        description={errorText}
        btnTitle="Закрыть"
        btnAction={() => {
          if (errorText === TOKEN_ERROR) {
            dispatch(setVisibleErrorModal(false));
            dispatch(clearTokenData());
            dispatch(autoApi.util.resetApiState());
            navigate('/auth');
          } else {
            dispatch(setVisibleErrorModal(false));
          }
        }}
      />
    </div>
  );
};

export default AddAutoPage;
