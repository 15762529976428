import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import { Authorization } from '../../features';
import { useAppSelector } from '../../shared/config/hooks';

import { InstallPopup } from '../../shared/ui';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const AuthPage: React.FC = () => {
  const navigate = useNavigate();
  const token = useAppSelector((state) => state.auth.token);

  useEffect(() => {
    if (token) {
      navigate('/');
    }
  }, [token]);

  return (
    <div className={cx('container', 'full-height')}>
      <div className={cx('auth__container')}>
        <Authorization />
        <InstallPopup />
      </div>
    </div>
  );
};

export default AuthPage;
