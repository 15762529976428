import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button } from '@mui/material';

import iconPlus from '../../assets/icons/icon_plus.svg';
import iconPlusWhite from '../../assets/icons/icon_plus_white.svg';
import { userApi, useGetUserNameQuery } from '../../app/api/userApi';
import { autoApi, useGetCarsQuery } from '../../app/api/autoApi';
import { useAppDispatch, useAppSelector } from '../../shared/config/hooks';

import { setAutoId, setAutoListStore, setVisibleErrorModal } from '../../shared/store/slices/globalSlice';

import { IAuto } from '../../shared/config/interfaces';

import { ErrorModal, Loader } from '../../shared/ui';

import { clearTokenData } from '../../shared/store/slices/authSlice';

import { TOKEN_ERROR } from '../../shared/config/constants';

import { AutoItem } from './ui';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const GaragePage: React.FC = () => {
  const [selectedAutoId, setSelectedAutoId] = useState<number | null>(null);
  const [autoList, setAutoList] = useState<IAuto[]>([]);
  const [errorText, setErrorText] = useState<string>('');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userName = useAppSelector((state) => state.auth.userName);

  const handleError = (error: unknown) => {
    if (error && typeof error === 'object' && 'data' in error) {
      const errorData = (error as { data: { errors?: { detail?: string }[] } }).data;
      if (Array.isArray(errorData.errors) && errorData.errors[0]?.detail) {
        setErrorText(errorData.errors[0].detail);
        dispatch(setVisibleErrorModal(true));
      }
    }
  };

  const {
    data: userData,
    isSuccess,
    isError: isErrorUserName,
    error: errorUserName,
  } = useGetUserNameQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isSuccess) {
      if (!userName && !userData?.first_name) {
        navigate('/new-user');
      }
    }
    if (isErrorUserName && errorUserName) {
      handleError(errorUserName);
    }
  }, [userData, isSuccess, userName, errorUserName]);

  const {
    data: cars,
    isLoading,
    isError: isErrorUserCars,
    error: errorCars,
  } = useGetCarsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (cars) {
      setAutoList(cars);
      dispatch(setAutoListStore(cars));
    }
    if (isErrorUserCars && errorCars) {
      handleError(errorCars);
    }
  }, [cars, errorCars]);

  return (
    <div className={cx('container', 'full-height')}>
      <div className={cx('garage__container')}>
        <div className={cx('heading')}>
          <h2>Гараж</h2>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className={'full-height'}>
            {autoList.length !== 0 ? (
              <>
                <div className={cx('h3')}>
                  <span className={cx('h3-inner')}>Для записи выберите авто</span>
                </div>
                <Button
                  variant="contained"
                  className={cx('button-text', 'button-text--small', 'button--add-auto')}
                  onClick={() => navigate('/add-auto')}
                >
                  <img src={iconPlus} alt="" />
                  Добавить авто
                </Button>
                <div className={cx('cars__list')}>
                  {autoList.map((auto) => (
                    <AutoItem
                      key={auto.id}
                      auto={auto}
                      isSelected={selectedAutoId === auto.id}
                      setSelectedAutoId={setSelectedAutoId}
                    />
                  ))}
                </div>
                <div className={cx('buttons-group', 'buttons-group--cars-list')}>
                  <Button
                    variant="contained"
                    type="submit"
                    className={cx('button', 'button-service')}
                    onClick={() => {
                      dispatch(setAutoId(selectedAutoId));
                      navigate(`/services/${selectedAutoId}`);
                    }}
                    disabled={selectedAutoId === null}
                  >
                    Запись на сервис
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    className={cx('button', 'button-border', 'button-carwash')}
                    onClick={() => {
                      dispatch(setAutoId(selectedAutoId));
                      navigate(`/washing/${selectedAutoId}`);
                    }}
                    disabled={selectedAutoId === null}
                  >
                    Запись на мойку
                  </Button>
                </div>
              </>
            ) : (
              <div className={cx('wrapper')}>
                <div className={cx('h3')}>
                  <span className={cx('h3-inner')}>В вашем гараже пока пусто. </span>
                  <span className={cx('h3-inner')}>Для записи на сервис или мойку добавьте авто</span>
                </div>
                <Button
                  variant="contained"
                  className={cx('button')}
                  onClick={() => navigate('/add-auto')}
                >
                  <img src={iconPlusWhite} alt="" />
                  Добавить авто
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      <ErrorModal
        description={errorText}
        btnTitle="Закрыть"
        btnAction={() => {
          if (errorText === TOKEN_ERROR) {
            dispatch(setVisibleErrorModal(false));
            dispatch(clearTokenData());
            dispatch(userApi.util.resetApiState());
            dispatch(autoApi.util.resetApiState());
            navigate('/auth');
          } else {
            dispatch(setVisibleErrorModal(false));
          }
        }}
      />
    </div>
  );
};

export default GaragePage;
