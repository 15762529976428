import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Button } from '@mui/material';

import { useGetSmsCodeMutation } from '../../../../app/api/authApi';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface ICustomTimerProps {
  phoneNumber: string | null;
}

const CustomTimer: React.FC<ICustomTimerProps> = ({ phoneNumber }) => {
  const [timeLeft, setTimeLeft] = useState(60);
  const [isActive, setIsActive] = useState(true);

  const [getSmsCode, { isSuccess }] = useGetSmsCodeMutation({});

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let timer: NodeJS.Timeout | undefined;
    if (isActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsActive(false);
    }
    return () => clearInterval(timer);
  }, [isActive, timeLeft]);

  const resetTimer = () => {
    setIsActive(false);
    setTimeLeft(60);
  };

  useEffect(() => {
    if (isSuccess) {
      resetTimer();
      setIsActive(true);
    }
  }, [isSuccess]);

  return (
    <Button
      variant="contained"
      className={cx('button-text', 'w100')}
      disabled={isActive}
      onClick={() => {
        getSmsCode({
          phone: phoneNumber,
        });
      }}
    >
      {`Отправить код повторно ${timeLeft}`}
    </Button>
  );
};

export default CustomTimer;
