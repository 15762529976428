import { createSlice } from '@reduxjs/toolkit';

import { IAuth } from '../../config/interfaces';

import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: IAuth = {
  token: null,
  userName: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>): void => {
      state.token = action.payload;
    },
    clearTokenData: (state) => {
      state.token = null;
    },
    setUserName: (state, action: PayloadAction<string | null>): void => {
      state.userName = action.payload;
    },
    clearUserName: (state) => {
      state.userName = null;
    },
  },
});

export const {
  setToken,
  clearTokenData,
  setUserName,
  clearUserName,
} = authSlice.actions;
