import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_API_URL;

interface RootState {
  auth: {
    token: string;
  };
}

export const serviceCentersApi = createApi({
  reducerPath: 'serviceCentersApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;
      headers.set('Content-Type', 'application/json');
      headers.set('Authorization', `Token ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getLocationServiceCenters: builder.query<any, any>({
      query: () => '/api/reference/service-centers/',
    }),
  }),
});

export const {
  useGetLocationServiceCentersQuery,
  useLazyGetLocationServiceCentersQuery,
} = serviceCentersApi;
