import React from 'react';
import classNames from 'classnames/bind';

import { IService } from '../../../../shared/config/interfaces';

import { EServiceTypes } from '../../../../shared/config/enums';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface IServiceItemProps {
  service: IService
  type: EServiceTypes
}

const ServiceItem: React.FC<IServiceItemProps> = ({
  service,
  type,
}) => {
  const {
    name,
    options,
  } = service;

  const getAmount = (obj: IService) => {
    return obj.options.reduce((total, option) => total + option.total_price, 0);
  };

  return (
    type === EServiceTypes.DIAGNOSTIC ? (
      <div className={cx('services__item')}>
        <div className={cx('services__item--name')}>{name}</div>
        <div className={cx('services__item--price-wrapper')}>
          <div className={cx('services__item--price')}>
            {`${getAmount(service).toLocaleString('ru-RU')} ₽`}
          </div>
        </div>
      </div>
    ) : (
      <div className={cx('services__item')}>
        <div className={cx('services__item--name')}>{name}</div>
        <div className={cx('services__item--description')}>{options[0].quality_level}</div>
        <div className={cx('services__item--price-wrapper')}>
          <div className={cx('services__item--price')}>{`${options[0].total_price.toLocaleString('ru-RU')} ₽`}</div>
        </div>
      </div>
    )
  );
};

export default ServiceItem;

