import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const Loader: React.FC = () => {
  return (
    <div className={cx('loader')}>
      <div className={cx('spinner')} />
    </div>
  );
};

export default Loader;
