import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { Button } from '@mui/material';
import classNames from 'classnames/bind';

import { SLIDER } from '../../config/constants';
import { ISlider } from '../../config/interfaces';

import { useCreateCallbackMutation } from '../../../../app/api/userApi';

import { ModalWindow } from '../../../../shared/ui';

import { useAppSelector } from '../../../../shared/config/hooks';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const SliderComponent: React.FC = () => {
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const autoId = useAppSelector((state) => state.global.autoId);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };

  const [createCallback, { isSuccess }] = useCreateCallbackMutation({});

  useEffect(() => {
    if (isSuccess) {
      setIsVisibleModal(true);
    }
  }, [isSuccess]);

  return (
    <>
      <Slider {...settings}>
        {SLIDER.map((slide: ISlider) => (
          <div key={slide.id} className={cx('slide__item')}>
            <div className={cx('slide__item--discount')}>{slide.discount}</div>
            <h3>{slide.name}</h3>
            <div className={cx('slide__item--description')}>{slide.description}</div>
            <Button
              className={cx('button', 'button--small', 'w100', 'button--slider')}
              onClick={() => createCallback({
                callback_type: slide.type,
                car_id: autoId,
              })}
            >
              {slide.btnText}
            </Button>
          </div>
        ))}
      </Slider>
      {isVisibleModal && (
        <ModalWindow
          title="Успешно"
          description="Ваш запрос отправлен"
          firstBtnTitle="В гараж"
          secondBtnTitle="Закрыть"
          firstBtnAction={() => navigate('/')}
          secondBtnAction={() => setIsVisibleModal(false)}
        />
      )}
    </>

  );
};

export default SliderComponent;
