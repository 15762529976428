import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const JivoChat: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    let script: HTMLScriptElement | null = null;

    if (pathname === '/contacts' && !window.jivo_inited) {
      script = document.createElement('script');
      script.src = '//code.jivo.ru/widget/mtt73JDCSr';
      script.onload = () => {
        if (window.jivo_init) {
          window.jivo_init();
          window.jivo_inited = true;
          // eslint-disable-next-line no-console
          console.log('Jivo загружен и инициализирован');
        }
      };
      script.onerror = () => {
        // eslint-disable-next-line no-console
        console.log('Ошибка при загрузке скрипта');
      };
      document.body.appendChild(script);
    } else if (window.jivo_destroy) {
      window.jivo_destroy();
      window.jivo_inited = false;
      // eslint-disable-next-line no-console
      console.log('Jivo удален');
    }

    return () => {
      if (pathname === '/contacts' && script && document.body.contains(script)) {
        document.body.removeChild(script);
        if (window.jivo_destroy) {
          window.jivo_destroy();
          window.jivo_inited = false;
          // eslint-disable-next-line no-console
          console.log('Jivo удален');
        }
      }
    };
  }, [pathname]);

  return null;
};

export default JivoChat;
