import React, { useEffect, useState } from 'react';
import {
  useForm,
  Controller,
  SubmitHandler,
} from 'react-hook-form';
import classNames from 'classnames/bind';

import { Button, TextField } from '@mui/material';

import { IPhoneFormFields } from '../../config/interfaces';

import { useGetSmsCodeMutation } from '../../../../app/api/authApi';

import { phoneRegExp } from '../../config/constants';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface ISubmitForm {
  phone: string
  agreeRules: boolean
  agreeMailing: boolean
}

interface IPhoneStepProps {
  toNextStep: () => void
  setPhoneNumber: (arg: string) => void
}

const PhoneStep: React.FC<IPhoneStepProps> = ({ toNextStep, setPhoneNumber }) => {
  const [errorText, setErrorText] = useState<string | null>(null);

  const methods = useForm<IPhoneFormFields>({
    defaultValues:
      {
        phone: '',
        agreeRules: false,
        agreeMailing: false,
      },
  });

  const {
    control,
    handleSubmit,
    register,
    watch,
  } = methods;

  const [getSmsCode, { isSuccess, error }] = useGetSmsCodeMutation({});

  useEffect(() => {
    if (isSuccess) {
      toNextStep();
    }
    if (error) {
      if (typeof error === 'object' && 'data' in error) {
        const errorData = error.data as { errors?: { detail?: string }[] };
        if (Array.isArray(errorData.errors) && errorData.errors[0]?.detail) {
          setErrorText(errorData.errors[0].detail);
        }
      }
    }
  }, [isSuccess, error]);

  const onSubmit: SubmitHandler<ISubmitForm> = (data) => {
    setPhoneNumber(data.phone);
    getSmsCode({
      phone: data.phone,
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cx('form', 'full-height')}
      autoComplete="off"
    >
      <div className={cx('heading')}>
        <h2>Авторизация</h2>
        <div className={cx('h3')}>На&nbsp;ваш номер будет отправлен СМС с&nbsp;кодом подтверждения</div>
      </div>
      <div className={cx('form__row')}>
        <Controller
          name="phone"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Обязательное поле',
            },
            pattern: {
              value: phoneRegExp,
              message: 'Неверный формат телефона',
            },
          }}
          render={({ field, fieldState: { error: errorField } }) => (
            <TextField
              {...field}
              id="phone"
              label="Номер телефона"
              variant="outlined"
              type="tel"
              error={!!errorField}
              helperText={errorField ? errorField.message : null}
              onFocus={() => {
                if (!field.value) {
                  field.onChange('+7');
                }
              }}
              onChange={(e) => {
                const { value } = e.target;
                if (!/^\+7\d*$/.test(value)) {
                  return;
                }
                field.onChange(value);
              }}
              className={cx('form__input')}
              inputProps={{ maxLength: '12' }}
            />
          )}
        />
        {errorText && (
          <div className={cx('error')}>
            {errorText}
          </div>
        )}
      </div>
      <div className={cx('form__row')}>
        <div className={cx('checkbox-wrapper')}>
          <input
            id="agreeRules"
            type="checkbox"
            value=""
            {...register('agreeRules', {
              required: {
                value: true,
                message: 'Обязательное поле',
              },
            })}
          />
          <label htmlFor="agreeRules">
            <span className={cx('label-text')}>
              Соглашаюсь с&nbsp;
              <span className={cx('font-secondary')}>
                <a href="/documents/personal-data.pdf" target="_blank" rel="noreferrer">
                    политикой конфиденциальности
                </a>
              </span>
            </span>
          </label>
        </div>
      </div>
      <div className={cx('form__row')}>
        <div className={cx('checkbox-wrapper')}>
          <input
            id="agreeMailing"
            type="checkbox"
            value=""
            {...register('agreeMailing')}
          />
          <label htmlFor="agreeMailing">
            <span className={cx('label-text')}>Соглашаюсь на&nbsp;
              <span className={cx('font-secondary')}>
                <a href="/documents/license.pdf" target="_blank" rel="noreferrer">
                    получение рекламной рассылки
                </a>
              </span>
            </span>
          </label>
        </div>
      </div>
      <div className={cx('form__row', 'form__row--buttons')}>
        <Button
          variant="contained"
          type="submit"
          className={cx('button')}
          disabled={(
            watch('phone') === '' || !watch('agreeRules')
          )}
        >
          Войти
        </Button>
      </div>
    </form>
  );
};

export default PhoneStep;
